@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("../db.onlinewebfonts/fonts.css");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap");
/*bloco 3*/

.bloco2 {
  width: 100vw;
  height: 1203px;
  margin: 0;
  position: relative;
}

#Sup {
  position: absolute;
  width: 180px;
  height: 25px;
  right: 0;
  
  top: -12.5px;
  z-index: 999;
  background-color: #e3a14e;
}
.fundo {
  background-image: url(../../assets/Methodology/fundo.png);
  width: 375px;
  height: 1204px;
  margin-top: 12px;
  position: relative;
}
#svg {
  position: absolute;

  width: 110.69px;
  height: 145.48px;
}

#vector {
  position: absolute;

  width: 68px;
  height: 65px;
  margin-left: 30px;
  margin-top: 50px;
}

.icones h1 {
  position: absolute;

  width: 287px;
  height: 46px;
  left: 30px;
  top: 135px;
  right: 44px;
  margin: 0;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 46px;
  /* identical to box height */

  letter-spacing: 0.02em;

  color: #1f1f1f;
}
.texto p {
  position: absolute;

  height: 660px;
  left: 30px;
  right: 30px;
  top: 206px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  /* or 183% */

  color: #1f1f1f;
}

@media (min-width: 320px) and (max-width: 360px) {
  .bloco2 {
    width: 360px;
    height: 1203px;
    margin: 0;
    position: relative;
  }

  #Sup {
    position: absolute;
    width: 180px;
    height: 25px;
    right: 0;
    top: -12.5px;
    z-index: 999;
    background-color: #e3a14e;
  }
  .fundo {
    background-image: url(../../assets/Methodology/fundo.png);
    max-width: 360px;
    width: 100%;
    height: 1204px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 12px;
    position: relative;
  }
  #svg {
    position: absolute;

    width: 110.69px;
    height: 145.48px;
  }

  #vector {
    position: absolute;

    width: 68px;
    height: 65px;
    margin-left: 30px;
    margin-top: 50px;
  }

  .icones h1 {
    position: absolute;

    width: 287px;
    height: 46px;
    left: 30px;
    top: 135px;
    right: 44px;
    margin: 0;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #1f1f1f;
  }
  .texto p {
    position: absolute;

    height: 660px;
    left: 30px;
    right: 30px;
    top: 206px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    /* or 183% */

    color: #1f1f1f;
  }
}

@media (min-width: 361px) and (max-width: 769px) {
  .bloco2 {
    width: 100vw;
    height: 1203px;
    margin: 0;
    position: relative;
  }

  #Sup {
    position: absolute;
    width: 180px;
    height: 25px;
    right: 0;
    top: -12.5px;
     background-color: #e3a14e;
  }
  .fundo {
    background-image: url(../../assets/Methodology/fundo.png);
   width: 100vw;
    
    height: 1204px;
    margin-top: 12px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  #svg {
    position: absolute;

    width: 110.69px;
    height: 145.48px;
  }

  #vector {
    position: absolute;

    width: 68px;
    height: 65px;
    margin-left: 30px;
    margin-top: 50px;
  }

  .icones h1 {
    position: absolute;

    width: 287px;
    height: 46px;
    left: 30px;
    top: 135px;
    right: 44px;
    margin: 0;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #1f1f1f;
  }
  .texto p {
    position: absolute;

    height: 660px;
    left: 30px;
    right: 30px;
    top: 206px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    /* or 183% */

    color: #1f1f1f;
  }
}

@media (min-width: 769px) and (max-width: 1009px) {
  .bloco2 {
    width: 100vw;
    height: 848px;
    margin: 0;
    position: relative;
  }
  #Sup {
    position: absolute;
    width: 300px;
    height: 50px;
    right: -1px;
    top: -25px;
    background-color: #e3a14e;
  }

  .fundo {
    
    width: 100vw;
    height: 848px;
    background: url(../../assets/Methodology/fundo2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  #svg {
    position: absolute;
    width: 256.43px;
    height: 337.02px;
    top: -0.09px;
  }

  #vector {
    position: absolute;

    width: 68px;
    height: 65px;
    margin: 0;
    top: 100px;
    left: 50px;
  }

  .icones h1 {
    position: absolute;

    width: 510px;
    height: 74px;
    left: 50px;
    right: 276px;
    top: 185px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 74px;
    /* identical to box height, or 116% */

    letter-spacing: 0.02em;

    color: #1f1f1f;
  }
  .texto p {
    position: absolute;

    width: 734px;
    height: 264px;

    top: 309px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    /* or 183% */

    color: #1f1f1f;
  }
}
@media (min-width: 1010px) and (max-width: 1366px) {
  .bloco2 {
    width: 100vw;
    
    height: 800px;
    margin: auto;
    position: relative;
    margin-top: 10px;
  }

  #Sup {
    position: absolute;
    width: 535px;
    height: 100px;
    right: 0;
    top: -50px;
    z-index: 999;
    background-color: #e3a14e;
  }

  .fundo {
    background-image: url(../../assets/Methodology/fundo3.jpg);
    object-fit: cover;
    width: 100vw;
   
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 822px;
    margin-top: 100px;
    position: relative;
    display: flex;
  }
  #svg {
    position: absolute;
    width: 420px;
    height: 552px;
    margin-top: -68px;
  }

  #vector {
    position: absolute;

    width: 68px;
    height: 65px;
    margin: 0;
    top: 130px;
    left: 100px;
  }

  .icones {
    width: 50%;
  }
  .texto {
    width: 50%;
  }

  .icones h1 {
    position: absolute;

    width: 510px;
    height: 74px;
    left: 100px;
    top: 235px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 74px;

    letter-spacing: 0.02em;

    color: #1f1f1f;
  }
  .texto p {
    position: absolute;

    width: 558px;
    height: 352px;
    left: 708px;
    top: 130px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;

    color: #1f1f1f;
  }
}

@media (min-width: 1367px) {
  .bloco2 {
    width: 1366px;
    height: 801px;
    margin: auto;
    position: relative;
  }

  #Sup {
    position: absolute;
    width: 535px;
    height: 100px;
    right: 0;
    left:900px;
    top: -50px;
    z-index: 999;
    background-color: #e3a14e;
  }

  .fundo {
    background-image: url(../../assets/Methodology/fundo3.jpg);
    object-fit: cover;
    width: 1366px;
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 822px;
    margin-top: 100px;
    position: relative;
    display: flex;
  }
  #svg {
    position: absolute;
    width: 420px;
    height: 552px;
    margin-top: -68px;
  }

  #vector {
    position: absolute;

    width: 68px;
    height: 65px;
    margin: 0;
    top: 130px;
    left: 100px;
  }

  .icones {
    width: 50%;
  }
  .texto {
    width: 50%;
  }

  .icones h1 {
    position: absolute;

    width: 510px;
    height: 74px;
    left: 100px;
    top: 235px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 74px;

    letter-spacing: 0.02em;

    color: #1f1f1f;
  }
  .texto p {
    position: absolute;

    width: 558px;
    height: 352px;
    left: 708px;
    top: 130px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;

    color: #1f1f1f;
  }
}

/*CAROUSEL*/

.rec.rec-carousel-wrapper {
  max-width: 1366px;
  width: 100%;
  height: 680px;
  margin-top: 21px;
}

.rec.rec-arrow {
  position: absolute;
  left: 302px;
  z-index: 999;
  margin-top: 223px;
  background: none;
}
button {
  background: none;
}

button img {
  width: 50px;
  height: 145px;
}

.rec.rec-pagination {
  margin: 0;
  margin-top: 40px;
  width: auto;
  height: 20px;
}

.rec.rec-dot {
  margin: 0;
  margin-left: 20px;
  margin-top: 5px;
  box-sizing: border-box;

  width: 20px;
  height: 20px;

  border: 2px solid #8f53a1 !important;
  box-shadow: none !important;
}
.rec .krmNah:hover,
.rec.krmNah:focus {
  box-shadow: none;
  cursor: none;
}

.rec .krmNah {
  box-shadow: none !important;
  background-color: #8f53a1 !important;
  border: 2px solid #8f53a1 !important;
}

.rec-dot_active {
  box-shadow: none;
  background-color: #8f53a1 !important;
}

.rec .rec-slider-container {
  margin: 0;
}

rec .rec-arrow-left {
  display: none;
}
rec .rec-arrow-right {
  display: none;
}

.flip-box {
  background-color: transparent;
  max-width: 455px;
  width: 100%;
  height: 570px;

  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 462px;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#img1 .flip-box-front {
  background-color: #bbb;
  color: black;
}

.bg-purple {
  background-color: #8f53a1;
  color: white;
  transform: rotateY(180deg);
}

.bg-orange {
  background-color: #e3a14e;
  color: white;
  transform: rotateY(180deg);
}

.titulo {
  margin: 0;
  margin-left: 40px;
  margin-top: 40px;
  width: 401px;
  height: 128px;
  text-align: left;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 64px;
  z-index: 999;
  letter-spacing: 0.02em;
}
.titulo-esp {
  margin: 0;
  margin-left: 40px;
  margin-top: 40px;
  width: 401px;
  height: 128px;
  text-align: left;
  z-index: 999;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 64px;

  letter-spacing: 0.02em;
}

.conteudo {
  margin-top: 50px;
  width: 365px;
  height: 200px;
  left: 40px;
  top: 218px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-align: left;
  margin-inline: 40px;

  color: #ffffff;
}

.flips {
  width: 50.02px;
  height: 47.53px;
  margin-left: 360px;
  margin-top: 70px;
}

#flip {
  width: 50.02px;
  height: 47.53px;
}

#imagem {
  width: 462px;
  height: 570px;
}
.rec .rec-item-wrapper {
  width: 456px !important;
  padding: 0px;
}

.flip-front {
  margin-top: -150px;
  margin-left: 300px;
}

.botao-carousel-esquerda {
  position: absolute;
  display: flex;
  margin-left: -233px;
  z-index: 999;
}
.botao-carousel-direita {
  position: absolute;
  display: flex;
}



@media (min-width: 320px) and (max-width: 360px) {
  .rec.rec-carousel-wrapper {
    width: 360px;
    height: 535px;
    margin-top: 0px;
  }

  .rec.rec-arrow {
    border-radius: 0;
    background: none;

    box-shadow: none;
  }

  .rec .rec-arrow-left {
    position: absolute;
    z-index: 999;
    display: none;
  }

  .rec .rec-arrow-right {
    position: absolute;
    z-index: 999;
    margin-left: 323px;
    display: none;
  }

  .rec.rec-pagination {
    margin: 0;
    margin-top: 40px;
    width: auto;
    height: 20px;
  }

  .rec.rec-dot {
    margin: 0;
    margin-left: 20px;
    margin-top: 5px;
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    border: 2px solid #8f53a1;
    box-shadow: none;
  }
  .rec .krmNah:hover,
  .rec.krmNah:focus {
    box-shadow: none;
    cursor: none;
  }

  .rec .krmNah {
    box-shadow: none;
    background-color: #8f53a1;
    border: 2px solid #8f53a1;
  }

  .rec-dot_active {
    box-shadow: none;
  }
  .rec .rec-slider-container {
    margin: 0;
  }

  .flip-box {
    background-color: transparent;
    width: 360px;
    height: 450px;

    perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    max-width: 360px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  #img1 .flip-box-front {
    background-color: #bbb;
    color: black;
  }

  .titulo-front {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 335px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo-front-esp {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo {
    width: 336px;
    margin-left: 20px;
    z-index: 999;
    font-size: 50px;
  }
  .titulo-esp {
    width: 401px;
    margin-left: 20px;
    font-size: 50px;

    z-index: 999;
  }

  .conteudo {
    margin-top: 50px;
    width: 320px;
    height: 200px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: left;
    margin-inline: 20px;

    color: #ffffff;
  }
  .flips {
    width: 50.02px;
    height: 47.53px;
    margin-left: 285px;
    margin-top: -70px;
  }

  #flip {
    width: 50.02px;
    height: 47.53px;
  }

  .flip-front {
    margin-top: -150px;
    margin-left: 200px;
  }

  #imagem {
    width: 360px;
    height: 450px;
  }

  button img {
    width: 25px;
    height: 72.5px;
  }

  .botao-carousel-esquerda {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 20px;
    z-index: 999;
  }
  .botao-carousel-direita {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 332px;
  }
  .rec .rec-item-wrapper {
    width: 100vw !important;
    padding: 0px;
  }
}

@media (min-width: 361px) and (max-width: 629px) {
  .rec.rec-carousel-wrapper {
    max-width: 475px;
    width: 100%;
    height: 535px;
    margin-top: 0px;
  }

  .rec.rec-arrow {
    border-radius: 0;
    background: none;

    box-shadow: none;
  }

  .rec .rec-arrow-left {
    position: absolute;
    z-index: 999;
    display: none;
  }

  .rec .rec-arrow-right {
    position: absolute;
    z-index: 999;
    margin-left: 323px;
    display: none;
  }

  .rec.rec-pagination {
    margin: 0;
    margin-top: 40px;
    width: auto;
    height: 20px;
  }

  .rec.rec-dot {
    margin: 0;
    margin-left: 20px;
    margin-top: 5px;
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    border: 2px solid #8f53a1;
    box-shadow: none;
  }
  .rec .krmNah:hover,
  .rec.krmNah:focus {
    box-shadow: none;
    cursor: none;
  }

  .rec .krmNah {
    box-shadow: none;
    background-color: #8f53a1;
    border: 2px solid #8f53a1;
  }

  .rec-dot_active {
    box-shadow: none;
  }
  .rec .rec-slider-container {
    margin: 0;
  }
  .rec .rec-item-wrapper {
    width: 100vw !important;
    padding: 0px;
  }

  .flip-box {
    background-color: transparent;
    max-width: 475px;
    width: 100%;
    height: 450px;

    perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    max-width: 475px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  #img1 .flip-box-front {
    background-color: #bbb;
    color: black;
  }

  .titulo-front {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 335px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo-front-esp {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo {
    width: 336px;
    margin-left: 20px;
    z-index: 999;
    font-size: 50px;
  }
  .titulo-esp {
    width: 401px;
    margin-left: 20px;
    font-size: 50px;

    z-index: 999;
  }

  .conteudo {
    margin-top: 50px;
    width: 320px;
    height: 200px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: left;
    margin-inline: 20px;

    color: #ffffff;
  }
  .flips {
    width: 50.02px;
    height: 47.53px;
    margin-left: 285px;
    margin-top: -52px;
  }

  #flip {
    width: 50.02px;
    height: 47.53px;
  }

  .flip-front {
    margin-top: -150px;
    margin-left: 200px;
  }

  #imagem {
    max-width: 475px;
    width: 100%;
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  button img {
    width: 25px;
    height: 72.5px;
  }

  .botao-carousel-esquerda {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 20px;
    z-index: 999;
  }
  .botao-carousel-direita {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 332px;
  }
}
@media (min-width: 629px) and (max-width: 835px) {
  .rec.rec-carousel-wrapper {
    width: 832.5px;
    margin-top: 0px;
  }

  .rec.rec-arrow {
    border-radius: 0;
    background: none;

    box-shadow: none;
  }

  .rec .rec-arrow-left {
    position: absolute;
    z-index: 999;
    display: none;
  }

  .rec .rec-arrow-right {
    position: absolute;
    z-index: 999;
    margin-left: 750px;
    display: none;
  }

  .rec.rec-pagination {
    margin: 0;
    margin-top: 40px;
    width: auto;
    height: 20px;
  }

  .rec.rec-dot {
    margin: 0;
    margin-left: 20px;
    margin-top: 5px;
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    border: 2px solid #8f53a1;
    box-shadow: none;
  }
  .rec .krmNah:hover,
  .rec.krmNah:focus {
    box-shadow: none;
    cursor: none;
  }

  .rec .krmNah {
    box-shadow: none;
    background-color: #8f53a1;
    border: 2px solid #8f53a1;
  }

  .rec-dot_active {
    box-shadow: none;
  }

  .rec .rec-slider-container {
    margin: 0;
  }

  .rec .rec-slider-container {
    margin: 0;
  }

  .flip-box {
    background-color: transparent;
    width: 417px;
    height: 522px;

    perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    max-width: 835px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  #img1 .flip-box-front {
    background-color: #bbb;
    color: black;
  }

  .titulo-front {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo-front-esp {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo {
    margin-left: 30px;
    z-index: 999;
    font-size: 56px;
  }
  .titulo-esp {
    font-size: 56px;
    width: 401px;
    margin-left: 20px;

    z-index: 999;
  }

  .conteudo {
    margin-top: 50px;
    margin-left: 20px;
    width: 354px;
    height: 200px;
    left: 40px;
    top: 218px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    text-align: left;
    margin-inline: 40px;

    color: #ffffff;
  }

  .flips {
    width: 50.02px;
    height: 47.53px;
    margin-left: 310px;
    margin-top: 20px;
  }

  #flip {
    width: 50.02px;
    height: 47.53px;
  }

  #imagem {
    width: 417px;
    height: 522px;
  }
  button img {
    width: 50px;
    height: 145px;
  }

  .botao-carousel-esquerda {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 20px;
    z-index: 999;
  }
  .botao-carousel-direita {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 760px;
  }
  .rec .rec-item-wrapper {
    width: 417px !important;
    padding: 0px;
  }
}

@media (min-width: 836px) and (max-width: 1366px) {
  .rec.rec-carousel-wrapper {
    max-width: 1366px;
    width: 100%;
    margin-top: 0;
  }
  .rec-arrow-left {
    display: none;
  }
  .rec-arrow-right {
    display: none;
  }
  .rec.rec-arrow {
    position: absolute;
    left: 302px;
    z-index: 999;
    margin-top: 223px;
    background: none;
  }
  button {
    background: none;
  }

  button img {
    width: 50px;
    height: 145px;
  }

  .rec.rec-pagination {
    margin: 0;
    margin-top: 40px;
    width: auto;
    height: 20px;
  }

  .rec.rec-dot {
    margin: 0;
    margin-left: 20px;
    margin-top: 5px;
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    border: 2px solid #8f53a1;
    box-shadow: none;
  }
  .rec .krmNah:hover,
  .rec.krmNah:focus {
    box-shadow: none;
    cursor: none;
  }

  .rec .krmNah {
    box-shadow: none;
    background-color: #8f53a1;
    border: 2px solid #8f53a1;
  }

  .rec-dot_active {
    box-shadow: none;
  }

  .rec .rec-slider-container {
    margin: 0;
  }

  .flip-box {
    background-color: transparent;
    max-width: 461px;
    width: 100%;
    height: 570px;

    perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    max-width: 1366px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  #img1 .flip-box-front {
    background-color: #bbb;
    color: black;
  }

  .titulo-front {
    position: absolute;
    top: 40px;
    margin-inline: 30px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 64px;

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo-front-esp {
    position: absolute;
    top: 40px;
    margin-left: 30px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo {
    margin-left: 30px;
    z-index: 999;
  }
  .titulo-esp {
    width: 401px;
    margin-left: 30px;
    z-index: 999;
  }

  .conteudo {
    margin-top: 50px;
    width: 365px;
    height: 200px;
    left: 40px;
    top: 218px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    text-align: left;
    margin-inline: 40px;

    color: #ffffff;
  }

  .flips {
    width: 50.02px;
    height: 47.53px;
    margin-left: 360px;
    margin-top: 70px;
  }

  #flip {
    width: 50.02px;
    height: 47.53px;
  }

  #imagem {
    width: 464px;
    height: 570px;
  }

  .flip-front {
    margin-top: -150px;
    margin-left: 300px;
  }
  .botao-carousel-esquerda {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 27px;
    z-index: 999;
  }
  .botao-carousel-direita {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 1290px;
  }
}

@media (min-width: 1366px) {
  .rec.rec-carousel-wrapper {
    width: 1366px;
    margin: auto;
    margin-top: 21px;
  }
  .botao-carousel-esquerda {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 27px;
    z-index: 999;
  }
  .botao-carousel-direita {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 1290px;
  }
  .titulo-front {
    position: absolute;
    top: 40px;
    margin-inline: 30px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 64px;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
  .titulo-front-esp {
    position: absolute;
    top: 40px;
    margin-left: 30px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo {
    margin-left: 30px;
    z-index: 999;
  }
  .titulo-esp {
    width: 401px;
    margin-left: 30px;
    z-index: 999;
  }

  .rec-arrow-right {
    display: none;
  }
  .rec-arrow-left {
    display: none;
  }
}
