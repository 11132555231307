:root {
  --laranja1: #fd9526;
  --cinza1: #f2f2f2;
  --roxo1: #7a00fd;
  --roxo2: #2a1244;
  --verde1: #5be33a;
  --azul1: #26cbfd;
  --vermelho1: #fd2626;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none !important;
}

body {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  transform: translateZ(0.1px);
}

#nav-ul > li > .nav-active {
  color: var(--laranja1);
}

#nav-ul > li > .nav-active::after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--laranja1);
}

#drop-idioma {
  background-color: transparent !important;
}

.btn-secondary {
  background-color: transparent !important;
  color: var(--laranja1);
  border: none;
}

.show > .btn-secondary.dropdown-toggle {
  background-color: transparent !important;
  color: var(--laranja1);
  border: none;
}

.btn-secondary:hover {
  color: var(--laranja1);
}

#container-nav {
  position: sticky !important;
  top: 0px;
  z-index: 9999;
}






.underline-animation:not(.nav-active, .underline-animation-left)::after {
  transform: scaleX(0);
}

@media (hover) {
  .underline-animation:hover::after,
  .underline-animation:focus::after {
    transform: scaleX(1);
  }
}

.underline-animation-left {
  width: fit-content;
  border-bottom: 0 !important;
  color: black !important;
}

.underline-animation-left::after {
  transform-origin: left;
  transform: scaleX(0.45);
}

.underline-roxo::after {
  background: var(--roxo1);
}

.underline-verde::after {
  background: var(--verde1);
}

.underline-azul::after {
  background: var(--azul1);
}

.underline-vermelho::after {
  background: var(--vermelho1);
}

.nav-collapse-fix {
  display: flex !important;
  justify-content: center !important;
}

@media (min-width: 992px) {
  .nav-collapse-fix {
    display: none !important;
  }
}

/* Index */

#index-header {
  margin: 0;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

#index-header-text > h1 {
  font: St Transmission;
  font-size: calc(50px + 3vw);
  letter-spacing: 0px;
  color: #ffffff;
}

#header-arrow-down {
  position: absolute;
  bottom: 20px;
  left: calc(50% - 12.5px);
}

#linkEmail > i {
  font-size: 2rem;
  resize: both;
}

#header-social-icons {
  position: absolute;
  color: white;
  right: 20px;
  bottom: 20px;
}

#header-language-icons {
  position: absolute;
  color: white;
  left: 30px;
  bottom: 20px;
}

.language-icons-header {
  margin-left: 1rem;
  color: #fff;
}

.language-icons-header:hover {
  cursor: pointer;
}

.social-icons-footer {
  color: #707070;
}

.social-icons-footer:hover {
  color: var(--roxo2);
  cursor: pointer;
}

.social-icons-header {
  margin-left: 1rem;
  color: #fff;
}

.social-icons-header:hover {
  cursor: pointer;
}

.social-icons-container .social-icons-header,
.social-icons-footer,
.language-icons-header {
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  transition: all 0.5s;
}

.social-icons-container .social-icons-header:hover,
.social-icons-footer:hover,
.language-icons-header:hover {
  -moz-transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -o-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
}

.direita span {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  color: #8f53a1;
}

#index-section2-title {
  text-align: center;
  align-items: center;
}

.index-section2-card-title > svg {
  color: var(--laranja1);
}

.icone {
  margin-bottom: 20px;
  margin-left: 60px;
  padding: 0;
}

.index-section2-card-title > span {
  width: 308.67px;
  height: 160px;

  font-size: 26px;
  font-family: Gobold;
  font-weight: bold;
  letter-spacing: 0px;
  color: #1c1c1c;
}

.index-section2-card-content > span {
  font-size: 20px;
  font-family: St Transmission;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
}

.border-bottom-laranja {
  border-bottom: 8px solid var(--laranja1);
}

/* Bloco 3 */

.index-section3-row1 {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.index-section3-content > h1 {
  font-size: calc(40px + 2.3vw);
  font-family: Gobold;
  font-weight: bold;
  letter-spacing: 0px;
  color: #1c1c1c;
}

.index-section3-content > h2 {
  font-size: calc(40px + 2.3vw);
  font-family: Gobold Cuts;
  /* font-weight: bold; */
  letter-spacing: 0px;
  color: #1c1c1c;
}

.index-section3-content > span {
  font-size: 25px;
  font-family: St Transmission;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
}

#index-section3-title {
  max-width: 415px;
}

.index-section3-card > img {
  max-width: 100%;
  object-fit: cover;
}

.index-section3-card > h2 {
  letter-spacing: 0px;
  color: #1c1c1c;
  font-size: 30px;
  font-family: Gobold;
  font-weight: bold;
}

.index-section3-card > span {
  letter-spacing: 0px;
  color: grey;
  font-weight: bold;
  font-size: 18px;
  font-family: St Transmission;
}

.index-section3-card > a {
  font-size: 23px;
  font-family: "Roboto", sans-serif;
  /* font: normal normal normal 23px/35px Roboto; */
  letter-spacing: 0px;
  color: #000000;
}

.box-shadow {
  box-shadow: 0px 3px 30px #00000038;
}

/* FOOTER CONTATO */

#footer-contato-titulo {
  font: normal normal normal 30px Gobold;
  font-weight: bold;
  letter-spacing: 0px;
  color: #1c1c1c;
}

.footer-contato-conteudo {
  margin-top: 2rem;
}

.footer-contato-conteudo > h2 {
  font-size: 28px;
  font-family: St Pro Text;
  letter-spacing: 0px;
  color: #1c1c1c;
}

.footer-contato-conteudo > span {
  font-size: 15px;
  font-family: St Pro Text;
  letter-spacing: 0px;
  color: #1c1c1c;
}

.footer-contato-conteudo > a {
  font-size: 23px;
  font-family: "Roboto", sans-serif;
  /* font: normal normal normal 23px/35px Roboto; */
  letter-spacing: 0px;
  color: #000000;
}

.footer-contato-conteudo-link-span {
  font-size: 23px;
  font-family: "Roboto", sans-serif;
  /* font: normal normal normal 23px/35px Roboto; */
  letter-spacing: 0px;
  color: #000000;
}

.footer-contato-conteudo > input {
  border-radius: 37px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  width: 100%;
  max-width: 400px;
}

.footer-contato-conteudo > input::placeholder {
  color: #c3c3c3;
}

.footer-content > img {
  width: 100%;
  max-width: 220px;
}

.footer-content > h4 {
  font-size: 14px;
  font-family: St Pro Text;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
}

.footer-content > ul {
  padding: 0px;
}

.footer-content > ul > li {
  list-style: none;
  font-size: 14px;
  font-family: St Pro Text;
  letter-spacing: 0px;
  color: #747474;
 
}

.footer-content > ul > li > a {
  list-style: none;
  font-size: 14px;
  font-family: St Pro Text;
  letter-spacing: 0px;
  color: #747474;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

#form_unform {
  max-height: 50px !important;
  height: 100% !important;
}
#teste {
  padding: 0;
}
