@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap);
/*
      www.OnlineWebFonts.Com 
      You must credit the author Copy this link on your web 
      <div>Font made from <a href="http://www.onlinewebfonts.com">oNline Web Fonts</a>is licensed by CC BY 3.0</div>
      OR
      <a href="http://www.onlinewebfonts.com">oNline Web Fonts</a>
*/
@font-face {font-family: "SF Pro Text";
    src: url(/static/media/236987803794b916d95e7fd41ed4fee0.1d1519cd.eot); /* IE9*/
    src: url(/static/media/236987803794b916d95e7fd41ed4fee0-ie-fix.1d1519cd.eot) format("embedded-opentype"), 
    url(/static/media/236987803794b916d95e7fd41ed4fee0.cb241418.woff2) format("woff2"), 
    url(/static/media/236987803794b916d95e7fd41ed4fee0.fe56bfea.woff) format("woff"), 
    url(/static/media/236987803794b916d95e7fd41ed4fee0.0ddd2c9a.ttf) format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
}
.html {
  scroll-behavior: smooth;
}

.container-footer {
  
  width: 100vw;
  height: 371px;
  background-color: #8f53a1;
  position: relative;
}

.footer {
  width: 1366px;
  height: 371px;
  margin: auto;
  display: flex;
}

.footerA {
  width: 683px;
  display: flex;
  flex-direction: column;
}
.menu{
  cursor: pointer;
}


#logoFooter {
  width: 271.99px;
  height: 70px;
  margin-left: 100px;
  margin-top: 50px;
}

.menu {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-left: 100px;
  margin-top: 25px;
  text-decoration: none;
}

#menu1 {
  margin-top: 50px;
}

.menu:hover {
  color: white;
}

.footerB {
  width: 683px;
  display: flex;
  flex-direction: column;
}

#icones-footer {
  margin-left: 10px;
}
#icons-footer {
  margin-top: 71px;
  margin-left: 400px;
  display: flex;
}

#p1-footer {
  width: 304px;
  height: 14px;
  margin: 0;
  position: absolute;
  margin-top: 275px;
  margin-left: 369px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

#p2-footer {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 200px;
  margin-left: 295px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

#p2-footer span {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #e3a14e;
}

@media (min-width: 320px) and (max-width: 360px) {
  .container-footer {
    width: 100vw;
    height: 487px;
    background-color: #8f53a1;
    position: relative;
  }

  .footer {
    width: 360px;
    height: 487px;
    position: absolute;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .footerA {
    width: 360px;
    height: 243px;
    display: flex;
    flex-direction: column;
    z-index: 999;
  }

  #logoFooter {
    width: 271.99px;
    height: 70px;
    margin-left: 30px;
    margin-top: 25px;
  }

  #menu1 {
    margin-top: 105px;
  }

  .menu {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    align-items: center;
    margin-left: 30px;
    z-index: 999;
    color: #ffff;
  }

  .footerB {
    width: 360px;
    height: 243px;
    display: flex;
    flex-direction: column;
    
    
  }

  #icons-footer {
    position: absolute;
    width: 250px;
    height: 30px;
    left: -378px;
    top: 50px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 14px;
    gap: 14px;
  }

  #p1-footer {
    position: absolute;
    width: 242px;
    height: 14px;
    margin: 0;
    left: 30px;
    top: 401px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;

    color: #ffff;
  }

  #p2-footer {
    position: absolute;
    width: 218px;
    height: 28px;
    margin: 0;
    left: 30px;
    top: 421px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    text-align: left;

    color: #ffffff;
  }

  #p2-footer span {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #e3a14e;
  }
}

@media (min-width: 361px) and (max-width: 480px) {
  .container-footer {
    width: 100vw;
    height: 487px;
    background-color: #8f53a1;
    position: relative;
  }

  .footer {
    width: 375px;
    height: 487px;
    position: absolute;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .footerA {
    width: 375px;
    height: 243px;
    display: flex;
    flex-direction: column;
    z-index: 999;
  }

  .footerA Link{
    cursor: pointer;
  }

  #logoFooter {
    width: 271.99px;
    height: 70px;
    margin-left: 30px;
    margin-top: 25px;
  }


  #menu1 {
    margin-top: 105px;
  }

  .menu {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    z-index: 999;
    display: flex;
    align-items: center;
    margin-left: 30px;

    color: #ffff;
  }

  .footerB {
    width: 375px;
    height: 243px;
    display: flex;
    flex-direction: column;
    
  }

  #icons-footer {
    position: absolute;
    width: 250px;
    height: 30px;
    left: -378px;
    top: 50px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 14px;
    gap: 14px;
  }

  #p1-footer {
    position: absolute;
    width: 242px;
    height: 14px;
    margin: 0;
    left: 30px;
    top: 401px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;

    color: #ffff;
  }

  #p2-footer {
    position: absolute;
    width: 218px;
    height: 28px;
    margin: 0;
    left: 30px;
    top: 421px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    text-align: left;

    color: #ffffff;
  }

  #p2-footer span {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #e3a14e;
  }
}

@media (min-width: 481px) and (max-width: 1009px) {
  .container-footer {
    width: 100vw;
    height: 371px;
    background-color: #8f53a1;
    position: relative;
  }

  .footer {
    max-width: 834px;
    min-width: 660px;
    height: 371px;
    display: flex;
    flex-direction: row;
  }

  .footerA {
    width: 417px;
    height: 371px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  #logoFooter {
    width: 271.99px;
    height: 70px;
    margin-left: 100px;
    margin-top: 50px;
  }

  .menu {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-left: 100px;
    margin-top: 25px;
  }

  #menu1 {
    margin-top: 50px;
  }

  .footerB {
    width: 417px;
    height: 371px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 999;
  }

  #icones {
    position: absolute;
    z-index: 999;
    margin-top: 10px;
  }
  #icons-footer {
    margin: 0;
    position: absolute;
    width: 190px;
    height: 30px;
    right: 100px;
    top: 71px;
  }

  #p1-footer {
    position: absolute;
    width: 238px;
    height: 14px;
    right: 100px;
    bottom: 70px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    display: flex;
    align-items: center;
    text-align: right;
    color: #ffffff;
  }

  #p2-footer {
    position: absolute;
    width: 312px;
    height: 14px;
    right: 100px;
    bottom: 50px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  #p2-footer span {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #e3a14e;
  }
}

/*bloco 3*/

.bloco2 {
  width: 100vw;
  height: 1203px;
  margin: 0;
  position: relative;
}

#Sup {
  position: absolute;
  width: 180px;
  height: 25px;
  right: 0;
  
  top: -12.5px;
  z-index: 999;
  background-color: #e3a14e;
}
.fundo {
  background-image: url(/static/media/fundo.19be350e.png);
  width: 375px;
  height: 1204px;
  margin-top: 12px;
  position: relative;
}
#svg {
  position: absolute;

  width: 110.69px;
  height: 145.48px;
}

#vector {
  position: absolute;

  width: 68px;
  height: 65px;
  margin-left: 30px;
  margin-top: 50px;
}

.icones h1 {
  position: absolute;

  width: 287px;
  height: 46px;
  left: 30px;
  top: 135px;
  right: 44px;
  margin: 0;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 46px;
  /* identical to box height */

  letter-spacing: 0.02em;

  color: #1f1f1f;
}
.texto p {
  position: absolute;

  height: 660px;
  left: 30px;
  right: 30px;
  top: 206px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  /* or 183% */

  color: #1f1f1f;
}

@media (min-width: 320px) and (max-width: 360px) {
  .bloco2 {
    width: 360px;
    height: 1203px;
    margin: 0;
    position: relative;
  }

  #Sup {
    position: absolute;
    width: 180px;
    height: 25px;
    right: 0;
    top: -12.5px;
    z-index: 999;
    background-color: #e3a14e;
  }
  .fundo {
    background-image: url(/static/media/fundo.19be350e.png);
    max-width: 360px;
    width: 100%;
    height: 1204px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 12px;
    position: relative;
  }
  #svg {
    position: absolute;

    width: 110.69px;
    height: 145.48px;
  }

  #vector {
    position: absolute;

    width: 68px;
    height: 65px;
    margin-left: 30px;
    margin-top: 50px;
  }

  .icones h1 {
    position: absolute;

    width: 287px;
    height: 46px;
    left: 30px;
    top: 135px;
    right: 44px;
    margin: 0;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #1f1f1f;
  }
  .texto p {
    position: absolute;

    height: 660px;
    left: 30px;
    right: 30px;
    top: 206px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    /* or 183% */

    color: #1f1f1f;
  }
}

@media (min-width: 361px) and (max-width: 769px) {
  .bloco2 {
    width: 100vw;
    height: 1203px;
    margin: 0;
    position: relative;
  }

  #Sup {
    position: absolute;
    width: 180px;
    height: 25px;
    right: 0;
    top: -12.5px;
     background-color: #e3a14e;
  }
  .fundo {
    background-image: url(/static/media/fundo.19be350e.png);
   width: 100vw;
    
    height: 1204px;
    margin-top: 12px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  #svg {
    position: absolute;

    width: 110.69px;
    height: 145.48px;
  }

  #vector {
    position: absolute;

    width: 68px;
    height: 65px;
    margin-left: 30px;
    margin-top: 50px;
  }

  .icones h1 {
    position: absolute;

    width: 287px;
    height: 46px;
    left: 30px;
    top: 135px;
    right: 44px;
    margin: 0;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #1f1f1f;
  }
  .texto p {
    position: absolute;

    height: 660px;
    left: 30px;
    right: 30px;
    top: 206px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    /* or 183% */

    color: #1f1f1f;
  }
}

@media (min-width: 769px) and (max-width: 1009px) {
  .bloco2 {
    width: 100vw;
    height: 848px;
    margin: 0;
    position: relative;
  }
  #Sup {
    position: absolute;
    width: 300px;
    height: 50px;
    right: -1px;
    top: -25px;
    background-color: #e3a14e;
  }

  .fundo {
    
    width: 100vw;
    height: 848px;
    background: url(/static/media/fundo2.71bdcecd.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  #svg {
    position: absolute;
    width: 256.43px;
    height: 337.02px;
    top: -0.09px;
  }

  #vector {
    position: absolute;

    width: 68px;
    height: 65px;
    margin: 0;
    top: 100px;
    left: 50px;
  }

  .icones h1 {
    position: absolute;

    width: 510px;
    height: 74px;
    left: 50px;
    right: 276px;
    top: 185px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 74px;
    /* identical to box height, or 116% */

    letter-spacing: 0.02em;

    color: #1f1f1f;
  }
  .texto p {
    position: absolute;

    width: 734px;
    height: 264px;

    top: 309px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    /* or 183% */

    color: #1f1f1f;
  }
}
@media (min-width: 1010px) and (max-width: 1366px) {
  .bloco2 {
    width: 100vw;
    
    height: 800px;
    margin: auto;
    position: relative;
    margin-top: 10px;
  }

  #Sup {
    position: absolute;
    width: 535px;
    height: 100px;
    right: 0;
    top: -50px;
    z-index: 999;
    background-color: #e3a14e;
  }

  .fundo {
    background-image: url(/static/media/fundo3.0bf71e91.jpg);
    object-fit: cover;
    width: 100vw;
   
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 822px;
    margin-top: 100px;
    position: relative;
    display: flex;
  }
  #svg {
    position: absolute;
    width: 420px;
    height: 552px;
    margin-top: -68px;
  }

  #vector {
    position: absolute;

    width: 68px;
    height: 65px;
    margin: 0;
    top: 130px;
    left: 100px;
  }

  .icones {
    width: 50%;
  }
  .texto {
    width: 50%;
  }

  .icones h1 {
    position: absolute;

    width: 510px;
    height: 74px;
    left: 100px;
    top: 235px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 74px;

    letter-spacing: 0.02em;

    color: #1f1f1f;
  }
  .texto p {
    position: absolute;

    width: 558px;
    height: 352px;
    left: 708px;
    top: 130px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;

    color: #1f1f1f;
  }
}

@media (min-width: 1367px) {
  .bloco2 {
    width: 1366px;
    height: 801px;
    margin: auto;
    position: relative;
  }

  #Sup {
    position: absolute;
    width: 535px;
    height: 100px;
    right: 0;
    left:900px;
    top: -50px;
    z-index: 999;
    background-color: #e3a14e;
  }

  .fundo {
    background-image: url(/static/media/fundo3.0bf71e91.jpg);
    object-fit: cover;
    width: 1366px;
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 822px;
    margin-top: 100px;
    position: relative;
    display: flex;
  }
  #svg {
    position: absolute;
    width: 420px;
    height: 552px;
    margin-top: -68px;
  }

  #vector {
    position: absolute;

    width: 68px;
    height: 65px;
    margin: 0;
    top: 130px;
    left: 100px;
  }

  .icones {
    width: 50%;
  }
  .texto {
    width: 50%;
  }

  .icones h1 {
    position: absolute;

    width: 510px;
    height: 74px;
    left: 100px;
    top: 235px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 74px;

    letter-spacing: 0.02em;

    color: #1f1f1f;
  }
  .texto p {
    position: absolute;

    width: 558px;
    height: 352px;
    left: 708px;
    top: 130px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;

    color: #1f1f1f;
  }
}

/*CAROUSEL*/

.rec.rec-carousel-wrapper {
  max-width: 1366px;
  width: 100%;
  height: 680px;
  margin-top: 21px;
}

.rec.rec-arrow {
  position: absolute;
  left: 302px;
  z-index: 999;
  margin-top: 223px;
  background: none;
}
button {
  background: none;
}

button img {
  width: 50px;
  height: 145px;
}

.rec.rec-pagination {
  margin: 0;
  margin-top: 40px;
  width: auto;
  height: 20px;
}

.rec.rec-dot {
  margin: 0;
  margin-left: 20px;
  margin-top: 5px;
  box-sizing: border-box;

  width: 20px;
  height: 20px;

  border: 2px solid #8f53a1 !important;
  box-shadow: none !important;
}
.rec .krmNah:hover,
.rec.krmNah:focus {
  box-shadow: none;
  cursor: none;
}

.rec .krmNah {
  box-shadow: none !important;
  background-color: #8f53a1 !important;
  border: 2px solid #8f53a1 !important;
}

.rec-dot_active {
  box-shadow: none;
  background-color: #8f53a1 !important;
}

.rec .rec-slider-container {
  margin: 0;
}

rec .rec-arrow-left {
  display: none;
}
rec .rec-arrow-right {
  display: none;
}

.flip-box {
  background-color: transparent;
  max-width: 455px;
  width: 100%;
  height: 570px;

  -webkit-perspective: 1000px;

          perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 462px;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#img1 .flip-box-front {
  background-color: #bbb;
  color: black;
}

.bg-purple {
  background-color: #8f53a1;
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.bg-orange {
  background-color: #e3a14e;
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.titulo {
  margin: 0;
  margin-left: 40px;
  margin-top: 40px;
  width: 401px;
  height: 128px;
  text-align: left;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 64px;
  z-index: 999;
  letter-spacing: 0.02em;
}
.titulo-esp {
  margin: 0;
  margin-left: 40px;
  margin-top: 40px;
  width: 401px;
  height: 128px;
  text-align: left;
  z-index: 999;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 64px;

  letter-spacing: 0.02em;
}

.conteudo {
  margin-top: 50px;
  width: 365px;
  height: 200px;
  left: 40px;
  top: 218px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-align: left;
  margin-inline: 40px;

  color: #ffffff;
}

.flips {
  width: 50.02px;
  height: 47.53px;
  margin-left: 360px;
  margin-top: 70px;
}

#flip {
  width: 50.02px;
  height: 47.53px;
}

#imagem {
  width: 462px;
  height: 570px;
}
.rec .rec-item-wrapper {
  width: 456px !important;
  padding: 0px;
}

.flip-front {
  margin-top: -150px;
  margin-left: 300px;
}

.botao-carousel-esquerda {
  position: absolute;
  display: flex;
  margin-left: -233px;
  z-index: 999;
}
.botao-carousel-direita {
  position: absolute;
  display: flex;
}



@media (min-width: 320px) and (max-width: 360px) {
  .rec.rec-carousel-wrapper {
    width: 360px;
    height: 535px;
    margin-top: 0px;
  }

  .rec.rec-arrow {
    border-radius: 0;
    background: none;

    box-shadow: none;
  }

  .rec .rec-arrow-left {
    position: absolute;
    z-index: 999;
    display: none;
  }

  .rec .rec-arrow-right {
    position: absolute;
    z-index: 999;
    margin-left: 323px;
    display: none;
  }

  .rec.rec-pagination {
    margin: 0;
    margin-top: 40px;
    width: auto;
    height: 20px;
  }

  .rec.rec-dot {
    margin: 0;
    margin-left: 20px;
    margin-top: 5px;
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    border: 2px solid #8f53a1;
    box-shadow: none;
  }
  .rec .krmNah:hover,
  .rec.krmNah:focus {
    box-shadow: none;
    cursor: none;
  }

  .rec .krmNah {
    box-shadow: none;
    background-color: #8f53a1;
    border: 2px solid #8f53a1;
  }

  .rec-dot_active {
    box-shadow: none;
  }
  .rec .rec-slider-container {
    margin: 0;
  }

  .flip-box {
    background-color: transparent;
    width: 360px;
    height: 450px;

    -webkit-perspective: 1000px;

            perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    max-width: 360px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  #img1 .flip-box-front {
    background-color: #bbb;
    color: black;
  }

  .titulo-front {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 335px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo-front-esp {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo {
    width: 336px;
    margin-left: 20px;
    z-index: 999;
    font-size: 50px;
  }
  .titulo-esp {
    width: 401px;
    margin-left: 20px;
    font-size: 50px;

    z-index: 999;
  }

  .conteudo {
    margin-top: 50px;
    width: 320px;
    height: 200px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: left;
    margin-inline: 20px;

    color: #ffffff;
  }
  .flips {
    width: 50.02px;
    height: 47.53px;
    margin-left: 285px;
    margin-top: -70px;
  }

  #flip {
    width: 50.02px;
    height: 47.53px;
  }

  .flip-front {
    margin-top: -150px;
    margin-left: 200px;
  }

  #imagem {
    width: 360px;
    height: 450px;
  }

  button img {
    width: 25px;
    height: 72.5px;
  }

  .botao-carousel-esquerda {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 20px;
    z-index: 999;
  }
  .botao-carousel-direita {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 332px;
  }
  .rec .rec-item-wrapper {
    width: 100vw !important;
    padding: 0px;
  }
}

@media (min-width: 361px) and (max-width: 629px) {
  .rec.rec-carousel-wrapper {
    max-width: 475px;
    width: 100%;
    height: 535px;
    margin-top: 0px;
  }

  .rec.rec-arrow {
    border-radius: 0;
    background: none;

    box-shadow: none;
  }

  .rec .rec-arrow-left {
    position: absolute;
    z-index: 999;
    display: none;
  }

  .rec .rec-arrow-right {
    position: absolute;
    z-index: 999;
    margin-left: 323px;
    display: none;
  }

  .rec.rec-pagination {
    margin: 0;
    margin-top: 40px;
    width: auto;
    height: 20px;
  }

  .rec.rec-dot {
    margin: 0;
    margin-left: 20px;
    margin-top: 5px;
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    border: 2px solid #8f53a1;
    box-shadow: none;
  }
  .rec .krmNah:hover,
  .rec.krmNah:focus {
    box-shadow: none;
    cursor: none;
  }

  .rec .krmNah {
    box-shadow: none;
    background-color: #8f53a1;
    border: 2px solid #8f53a1;
  }

  .rec-dot_active {
    box-shadow: none;
  }
  .rec .rec-slider-container {
    margin: 0;
  }
  .rec .rec-item-wrapper {
    width: 100vw !important;
    padding: 0px;
  }

  .flip-box {
    background-color: transparent;
    max-width: 475px;
    width: 100%;
    height: 450px;

    -webkit-perspective: 1000px;

            perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    max-width: 475px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  #img1 .flip-box-front {
    background-color: #bbb;
    color: black;
  }

  .titulo-front {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 335px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo-front-esp {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo {
    width: 336px;
    margin-left: 20px;
    z-index: 999;
    font-size: 50px;
  }
  .titulo-esp {
    width: 401px;
    margin-left: 20px;
    font-size: 50px;

    z-index: 999;
  }

  .conteudo {
    margin-top: 50px;
    width: 320px;
    height: 200px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: left;
    margin-inline: 20px;

    color: #ffffff;
  }
  .flips {
    width: 50.02px;
    height: 47.53px;
    margin-left: 285px;
    margin-top: -52px;
  }

  #flip {
    width: 50.02px;
    height: 47.53px;
  }

  .flip-front {
    margin-top: -150px;
    margin-left: 200px;
  }

  #imagem {
    max-width: 475px;
    width: 100%;
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  button img {
    width: 25px;
    height: 72.5px;
  }

  .botao-carousel-esquerda {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 20px;
    z-index: 999;
  }
  .botao-carousel-direita {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 332px;
  }
}
@media (min-width: 629px) and (max-width: 835px) {
  .rec.rec-carousel-wrapper {
    width: 832.5px;
    margin-top: 0px;
  }

  .rec.rec-arrow {
    border-radius: 0;
    background: none;

    box-shadow: none;
  }

  .rec .rec-arrow-left {
    position: absolute;
    z-index: 999;
    display: none;
  }

  .rec .rec-arrow-right {
    position: absolute;
    z-index: 999;
    margin-left: 750px;
    display: none;
  }

  .rec.rec-pagination {
    margin: 0;
    margin-top: 40px;
    width: auto;
    height: 20px;
  }

  .rec.rec-dot {
    margin: 0;
    margin-left: 20px;
    margin-top: 5px;
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    border: 2px solid #8f53a1;
    box-shadow: none;
  }
  .rec .krmNah:hover,
  .rec.krmNah:focus {
    box-shadow: none;
    cursor: none;
  }

  .rec .krmNah {
    box-shadow: none;
    background-color: #8f53a1;
    border: 2px solid #8f53a1;
  }

  .rec-dot_active {
    box-shadow: none;
  }

  .rec .rec-slider-container {
    margin: 0;
  }

  .rec .rec-slider-container {
    margin: 0;
  }

  .flip-box {
    background-color: transparent;
    width: 417px;
    height: 522px;

    -webkit-perspective: 1000px;

            perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    max-width: 835px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  #img1 .flip-box-front {
    background-color: #bbb;
    color: black;
  }

  .titulo-front {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo-front-esp {
    position: absolute;
    top: 40px;
    margin-left: 20px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo {
    margin-left: 30px;
    z-index: 999;
    font-size: 56px;
  }
  .titulo-esp {
    font-size: 56px;
    width: 401px;
    margin-left: 20px;

    z-index: 999;
  }

  .conteudo {
    margin-top: 50px;
    margin-left: 20px;
    width: 354px;
    height: 200px;
    left: 40px;
    top: 218px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    text-align: left;
    margin-inline: 40px;

    color: #ffffff;
  }

  .flips {
    width: 50.02px;
    height: 47.53px;
    margin-left: 310px;
    margin-top: 20px;
  }

  #flip {
    width: 50.02px;
    height: 47.53px;
  }

  #imagem {
    width: 417px;
    height: 522px;
  }
  button img {
    width: 50px;
    height: 145px;
  }

  .botao-carousel-esquerda {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 20px;
    z-index: 999;
  }
  .botao-carousel-direita {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 760px;
  }
  .rec .rec-item-wrapper {
    width: 417px !important;
    padding: 0px;
  }
}

@media (min-width: 836px) and (max-width: 1366px) {
  .rec.rec-carousel-wrapper {
    max-width: 1366px;
    width: 100%;
    margin-top: 0;
  }
  .rec-arrow-left {
    display: none;
  }
  .rec-arrow-right {
    display: none;
  }
  .rec.rec-arrow {
    position: absolute;
    left: 302px;
    z-index: 999;
    margin-top: 223px;
    background: none;
  }
  button {
    background: none;
  }

  button img {
    width: 50px;
    height: 145px;
  }

  .rec.rec-pagination {
    margin: 0;
    margin-top: 40px;
    width: auto;
    height: 20px;
  }

  .rec.rec-dot {
    margin: 0;
    margin-left: 20px;
    margin-top: 5px;
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    border: 2px solid #8f53a1;
    box-shadow: none;
  }
  .rec .krmNah:hover,
  .rec.krmNah:focus {
    box-shadow: none;
    cursor: none;
  }

  .rec .krmNah {
    box-shadow: none;
    background-color: #8f53a1;
    border: 2px solid #8f53a1;
  }

  .rec-dot_active {
    box-shadow: none;
  }

  .rec .rec-slider-container {
    margin: 0;
  }

  .flip-box {
    background-color: transparent;
    max-width: 461px;
    width: 100%;
    height: 570px;

    -webkit-perspective: 1000px;

            perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    max-width: 1366px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  #img1 .flip-box-front {
    background-color: #bbb;
    color: black;
  }

  .titulo-front {
    position: absolute;
    top: 40px;
    margin-inline: 30px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 64px;

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo-front-esp {
    position: absolute;
    top: 40px;
    margin-left: 30px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo {
    margin-left: 30px;
    z-index: 999;
  }
  .titulo-esp {
    width: 401px;
    margin-left: 30px;
    z-index: 999;
  }

  .conteudo {
    margin-top: 50px;
    width: 365px;
    height: 200px;
    left: 40px;
    top: 218px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    text-align: left;
    margin-inline: 40px;

    color: #ffffff;
  }

  .flips {
    width: 50.02px;
    height: 47.53px;
    margin-left: 360px;
    margin-top: 70px;
  }

  #flip {
    width: 50.02px;
    height: 47.53px;
  }

  #imagem {
    width: 464px;
    height: 570px;
  }

  .flip-front {
    margin-top: -150px;
    margin-left: 300px;
  }
  .botao-carousel-esquerda {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 27px;
    z-index: 999;
  }
  .botao-carousel-direita {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 1290px;
  }
}

@media (min-width: 1366px) {
  .rec.rec-carousel-wrapper {
    width: 1366px;
    margin: auto;
    margin-top: 21px;
  }
  .botao-carousel-esquerda {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 27px;
    z-index: 999;
  }
  .botao-carousel-direita {
    position: absolute;
    display: none;
    margin-top: 200px;
    margin-left: 1290px;
  }
  .titulo-front {
    position: absolute;
    top: 40px;
    margin-inline: 30px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 64px;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
  .titulo-front-esp {
    position: absolute;
    top: 40px;
    margin-left: 30px;
    width: 388px;
    text-align: left;
    height: 128px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 64px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .titulo {
    margin-left: 30px;
    z-index: 999;
  }
  .titulo-esp {
    width: 401px;
    margin-left: 30px;
    z-index: 999;
  }

  .rec-arrow-right {
    display: none;
  }
  .rec-arrow-left {
    display: none;
  }
}

.bloco1 {
  margin: 0;
  padding: 0;
  width: 1366px;
  margin: auto;
  margin-top: 100px;
  display: flex;
}
.col-a {
  width: 620px;
  height: 795px;
  margin-left: 100px;
}

#text1 {
  max-width: 620px;
  height: 144px;
  margin-top: 50px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;

  color: #8f53a1;
}

#text2 {
  max-width: 520px;
  height: 396px;
  margin-top: 50px;
  margin-left: 100px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;

  color: #1f1f1f;
}

.col-a img {
  width: 620px;
  height: 155px;
}

.col-b {
  background-image: url(/static/media/homemCelular.46629358.jpg);

  width: 546px;
  height: 695px;
  margin-left: 100px;
  margin-top: 100px;
}
.col-b img {
  width: 546px;
  height: 695px;
}

@media (min-width: 320px) and (max-width: 360px) {
  .bloco1 {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 360px;
    height: 1035px;
    margin: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .col-a {
    width: 360px;
    height: 795px;
    margin: 0;

    position: absolute;
  }

  #text1 {
    width: 300px;
    height: 144px;
    margin-top: 25px;
    margin-left: 30px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;

    color: #8f53a1;
  }

  #text2 {
    width: 300px;
    height: 432px;
    margin-left: 30px;
    margin-top: 360px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    color: #1f1f1f;
  }

  .col-a img {
    width: 300px;
    height: 75px;
    margin-left: 30px;
  }

  .col-b {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(/static/media/homemCelularAndroid.9ce883a6.jpg);
    margin: 0;
    margin-top: 270px;
    width: 100%;
    max-width: 360px;
    height: 300px;
    position: absolute;
  }
}

@media (min-width: 361px) and (max-width: 730px) {
  .bloco1 {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 480px;
    height: 1035px;
    margin: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .col-a {
    width: 375px;
    height: 1035px;
    margin: 0;

    position: absolute;
  }

  #text1 {
    width: 300px;
    height: 144px;
    margin-top: 25px;
    margin-left: 30px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;

    color: #8f53a1;
  }

  #text2 {
    width: 315px;
    height: 432px;
    margin-left: 30px;
    margin-top: 355px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    color: #1f1f1f;
  }

  .col-a img {
    width: 315px;
    height: 78.75px;
    margin-left: 30px;
  }

  .col-b {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(/static/media/homemCelularIphone.dc86fbea.png);
    margin: 0;
    margin-top: 270px;
    width: 100%;
    max-width: 480px;
    height: 300px;
    position: absolute;
  }
  .col-b img {
    width: 375px;
    height: 695px;
  }
}

@media (min-width: 731px) and (max-width: 1009px) {
  .bloco1 {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 876px;
    margin: auto;
    margin-top: 50px !important;
    display: flex;
  }
  .col-a {
    width: 400px;
    height: 876px;
    margin-left: 50px;
  }

  #text1 {
    max-width: 400px;
    height: 192px;
    margin: 0;
    margin-top: 50px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;

    color: #8f53a1;
  }

  #text2 {
   max-width: 400px;
    height: 484px;
    margin: 0;
    margin-top: 50px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;

    color: #1f1f1f;
  }

  .col-a img {
    width: 400px;
    height: 100px;
  }

  .col-b {
    background-image: url(/static/media/homemCelularTablet.3a35fee4.jpg);

    width: 334px;
    height: 826px;
    margin-left: 50px;
    margin-top: 50px;
  }
}

/*BLOCO TODO NEGÓCIO É DIGITAL*/

.digital {
  width: 1366px;
  height: 593px;
  margin: auto;
  margin-top: 100px;
  position: relative;
}
.bloco {
  display: flex;
  width: 1366px;
  height: 593px;
}
#titulo {
  position: absolute;
  width: 776px;
  height: 96px;
  left: 295px;
  top: 0px;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 64px;

  color: #1f1f1f;
}
span {
  font-size: 100px;
}

.col1 {
  width: 548px;
  height: 593px;

  background-image: url(/static/media/mulher.9272ed7a.jpg);
}

.col2 {
  width: 818px;
  height: 593px;
  margin-left: 100px;
}

#p1 {
  width: 602px;
  height: 96px;
  margin-top: 171px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;

  color: #8f53a1;
}

#p2 {
  position: absolute;
  width: 518px;
  height: 176px;
  margin-left: 100px;
  margin-top: 50px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  color: #1f1f1f;
}

@media (min-width: 320px) and (max-width: 360px) {
  .digital {
    width: 100%;
    max-width: 360px;
    height: 663px;
    margin: auto;
    margin-top: 50px;
    position: relative;
  }
  .bloco {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    width: 100%;
    height: 663px;
  }
  #titulo {
    position: absolute;
    width: 329px;
    height: 133px;
    left: 15px;
    top: 84px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 76px;
    /* or 190% */

    color: #ffffff;
  }
  span {
    font-size: 100px;
  }

  .col1 {
    width: 100%;
    max-width: 360px;
    height: 487px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(/static/media/mulherAndroid.6cb06f31.jpg);
  }

  .col2 {
    width: 360px;
    height: 593px;
    margin: 0;
    
  }

  #p1 {
    position: absolute;
    width: 315px;
    height: 108px;
    margin: 0;
    margin-left: 30px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* or 150% */

    color: #8f53a1;
  }

  #p2 {
    position: absolute;
    width: 315px;
    height: 180px;
    margin: 0;
    margin-left: 30px;
    margin-top: 186px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    color: #1f1f1f;
    /* or 183% */

    color: #1f1f1f;
  }
}

@media (min-width: 361px) and (max-width: 730px) {
  .digital {
    width: 100%;
    max-width: 480px;
    height: 663px;
    margin: auto;
    margin-top: 50px;
    position: relative;
    
  }
  .bloco {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    height: 663px;
  }
  #titulo {
    position: absolute;
    width: 329px;
    height: 133px;
    left: 30px;
    top: 84px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 76px;
    /* or 190% */

    color: #ffffff;
  }
  span {
    font-size: 100px;
  }

  .col1 {
    width: 100%;
    max-width: 480px;
    height: 487px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(/static/media/mulherIphone.d9041c67.jpg);
  }

  .col2 {
    width: 375px;
    height: 593px;
    margin: 0;
    
  }

  #p1 {
    position: absolute;
    width: 315px;
    height: 108px;
    margin: 0;
    margin-left: 30px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* or 150% */

    color: #8f53a1;
  }

  #p2 {
    position: absolute;
    width: 315px;
    height: 180px;
    margin: 0;
    margin-left: 30px;
    margin-top: 170px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    color: #1f1f1f;
    /* or 183% */

    color: #1f1f1f;
  }
}

@media (min-width: 731px) and (max-width: 1009px) {
  .digital {
    width: 100vw;
    height: 663px;
    margin: auto;
    margin-top: 50px;
    position: relative;
  }
  .bloco {
    display: flex;
    width: 100vw;
    height: 663px;
  }
  #titulo {
    width: 634px;
    height: 128px;
    left: 106px;
    top: 0px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 76px;

    color: #1f1f1f;
  }
  span {
    font-size: 100px;
  }

  .col1 {
    width: 506px;
    height: 663px;

    background-image: url(/static/media/mulherTablet.c224c218.jpg);
  }

  .col2 {
    width: 818px;
    height: 593px;
    margin-left: 50px;
  }

  #p1 {
    position: absolute;
    width: 384px;
    height: 192px;

    top: 151px;
    margin: 0;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    /* or 150% */

    color: #8f53a1;
  }

  #p2 {
    position: absolute;
    width: 384px;
    height: 220px;
    margin: 0;
    top: 393px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    /* or 183% */

    color: #1f1f1f;
  }
}

/* PRODUTOS E EXPERIÊNCIAS*/

.containerBloco2 {
  width: 1366px;
  position: relative;
}

.superior {
  height: 100px;
    width: 535px;
    /* margin-left: 831px; */
    position: absolute;
    background: #c2dfe3;
    /* bottom: 145px; */
    /* top: 0; */
    right: 0;
}

#blocoDois {
  
  display: flex;
  position: relative;
  top: 112px;
}
.col-A {
  width: 500px;
  height: 666px;

  margin-left: -72px;
}

.col-B {
  width: 700px;
  height: 666px;
  margin-top: 100px;
  margin-left: 150px;
}

.col-B h2 {
  width: 670px;
  height: 164px;
  margin: 0;

  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 82px;
  letter-spacing: 0.02em;

  color: #02010a;
}

.col-B p {
  margin: 0;
  margin-top: 50px;

  width: 666px;
  height: 352px;
  left: 0px;
  top: 214px;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 44px;
}
#logo {
  margin-left: -482px;
  margin-top: -419px;
}

@media (min-width: 320px) and (max-width: 360px) {
  .containerBloco2 {
    width: 100vw;
  }
  .superior {
    width: 180px;
    height: 25px;
    right: 0;
    position: absolute;
    background: #c2dfe3;
  }

  #blocoDois {
    width: 100vw;
    max-width: 475px;
    height: 1120px;
    display: flex;
    margin-top: 30px;
    flex-direction: column;
  }
  .col-A {
    width: 100%;
    height: 300px;
    max-width: 360px;
  }

  #logo {
    width: 600px;
    margin-left: -244px;
    margin-top: -319px;
  }
  .col-B {
    width: 332px;
    height: 717px;
    margin: 0;
    margin-top: 25px;

    margin-inline: 30px;
  }

  .col-B h2 {
    width: 300px;
    height: 138px;
    
    margin: 0;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.02em;

    color: #02010a;
  }

  .col-B p {
    width: 315px;
    height: 504px;
    margin-top: 25px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    margin: 0;

    margin-top: 25px;

    color: #02010a;
  }
}

@media (min-width: 361px) and (max-width: 730px) {
  .containerBloco2 {
    width: 100vw;
  }
  .superior {
    width: 180px;
    height: 25px;
    right: 0px;
    position: absolute;
    background: #c2dfe3;
    
  }

  #blocoDois {
    width: 100vw;
    
    height: 1161px;
    display: flex;
    flex-direction: column;
  }
  .col-A {
    width: 100%;
    height: 300px;
    max-width: 360px;
  }

  #logo {
    width: 600px;
    margin-left: -244px;
    margin-top: -320px;
  }
  .col-B {
    width: 332px;
    height: 717px;
    margin: 0;
    margin-top: 25px;

    margin-inline: 30px;
  }

  .col-B h2 {
    width: 300px;
    height: 138px;
    /* margin-top: 25.47px; */
    margin: 0;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.02em;

    color: #02010a;
  }

  .col-B p {
    width: 315px;
    height: 504px;
    margin-top: 25px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    margin: 0;

    margin-top: 25px;

    color: #02010a;
  }
}


@media (min-width: 731px) and (max-width: 1009px) {
  .containerBloco2 {
    margin: 0;
    padding: 0;
    flex: 1 1;
    width: 100vw;
    position: relative;
  }

  .superior {
    width: 300px;
    height: 50px;
    position: absolute;
    right: 0;
    
    background: #c2dfe3;
  }
  #blocoDois {
    width: 100vw;
    height: 804px;
    
  }
  .col-A {
    width: 300px;
    height: 399.6px;
  }
  #logo {
    max-width: 610px;
    /* height: 399.6px; */
    margin-top: 180px;
    margin-left: -248px;
  }

  .col-B {
    max-width: 530px;
    height: 816px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
    margin-left: 120px;
  }
  .col-B h2 {
    width: 484px;
    height: 246px;
    margin: 0;
  }
  .col-B p {
    max-width: 484px;
    height: 528px;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;

    color: #02010a;
  }
}


@media (min-width: 1552px) {
  .containerBloco2 {
    width: 1500px;
    margin: auto;
  }
  .superior {
    margin-left: 966px;
    background: #c2dfe3;
  }
  #blocoDois {
    overflow: hidden;
    width: 1500px;
    height: 750px;
    margin: auto;
    display: flex;
  }
  .col-A {
    height: 757px;
    width: 741px;
  }
  #logo {
    margin-left: 76px;
    height: 757px;
    width: 741px;
    margin-top: -102px;
  }

  .col-B p {
    margin-top: 50px;
  }
}


/*SERVIÇOS*/

.servicos {
  width: 1184px;
  height: 1599px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 150px;
  margin-top: 50px;
}

.imagemGrid {
  margin-top: 53.26px;
  margin-left: 50px;
}

.tituloGrid {
  width: 320px;
  height: 41px;
  margin-top: 22.88px;
  margin-left: 50px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 41px;
  font-weight: bold;
  letter-spacing: 0.02em;

  color: #1f1f1f;
}

.paragrafoGrid {
  width: 313.5px;
  /* height: 120px; */

  font-family: 'Work Sans';
  font-style: normal !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  color: #1f1f1f;

  margin-left: 50px;
}

@media (min-width: 320px) and (max-width: 630px) {
  .servicos {
    width: 100%;
    max-width: 480px;
    height: 3140px;
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .imagemGrid {
    width: 80px;
    height: 80px;
    margin: 0;
  }
  .tituloGrid {
    width: 315px;
    height: 41px;
    margin: 0;
    margin-top: 21px;
    font-weight: 500;
    color: #1f1f1f;
    font-weight: bold;
  }
  .paragrafoGrid {
    width: 315px;

    margin: 0;
    margin-top: 20px;
    color: #1f1f1f;
  }

  .imagemGrid {
    margin-top: 25px;
  }

  .design {
    margin-inline: 30px;
  }
  #blocoSuperior {
    width: 180px;
    height: 25px;
    margin-left: 195px;

    background: #c2dfe3;
  }
}

@media (min-width: 631px) and (max-width: 772px) {
  .servicos {
    width: 100vw;
    height: 1983px;
    margin: 0px;
    padding: 4px;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-content: space-between;
  }

  .tituloGrid,
  .paragrafoGrid,
  .imagemGrid {
    margin: 0;
  }

  .imagemGrid {
    width: 144.98px;
    height: 156.52px;
  }
  .tituloGrid {
    margin-top: 20px;

    max-width: 313.5px;
    height: 41px;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 41px;
    /* identical to box height */
    font-weight: bold;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

  .paragrafoGrid {
    margin-top: 20px;

    max-width: 313.5px;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    color: #1f1f1f;
  }

  #blocoSuperior {
    width: 300px;
    height: 50px;
    margin-left: 534px;
  }
}

@media (min-width: 773px) and (max-width: 1009px) {
  .servicos {
    width: 100vw;
    height: 1983px;
    margin: 0px;
    margin-top: 100px;
    padding: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-content: space-between;
  }

  .tituloGrid,
  .paragrafoGrid,
  .imagemGrid {
    margin: 0;
  }

  .imagemGrid {
    width: 144.98px;
    height: 156.52px;
  }
  .tituloGrid {
    margin-top: 20px;

    max-width: 313.5px;
    height: 41px;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 41px;
    /* identical to box height */
    font-weight: bold;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

  .paragrafoGrid {
    margin-top: 20px;
    max-width: 313.5px;
    font-family: 'Work Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    color: #1f1f1f;
  }

  #blocoSuperior {
    width: 300px;
    height: 50px;
    margin-left: 534px;
  }
}

@media (min-width: 1367px) {
  .servicos {
    width: 1366px;

    margin: auto;
    margin-top: 100px;
    justify-content: center;
  }

  #img {
    display: none;
  }
}

.container__toogle .container__toogle + button {
  background: red;
  padding: 20px;
}

#container-nav {
  width: 100vw;
  height: 100px;
}

#menuReduzido {
  display: none;
}

.px-5-nav {
  padding-left: 3rem;
  padding-right: 3rem;
}

.navbar-toggler {
  height: 50px;
}

#nav-ul {
  width: 589px;
  height: 23px;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

#nav-ul li a {
  color: white;
}

#nav-ul > li {
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 40px;
  gap: 40px;
  width: 120px;
  height: 23px;
  position: relative;
  max-width: -webkit-min-content;
  max-width: min-content;
  font-family: "Work Sans";
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0px;
  color: white;
}

#nav-ul-menu-reduzido {
  width: 100vw;
  height: 177vw;
  background: rgba(2, 1, 10, 1);
  z-index: 999;
  position: relative;
}

.nav-bar1 {
  transition-duration: 1s;
  transition-property: height, background-color;
}

.navbar {
  height: 100px;
  
}
.navbar-nav{
  cursor: pointer;
}

#traducao a {
  width: 24px;
  height: 19px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.07em;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

#active {
  color: white;
}

@media (min-width: 320px) and (max-width: 471px) {
  #menuReduzido {
    display: block !important;
    width: 40px;
    height: 40px;
  }

  #reduzido {
    width: 100vw;
  }
  .open,
  .open > .navbar {
    background-color: #02010a;
  }

  .open #logo-menu-nav-top {
    margin-left: -18px !important;
  }

  #nav-ul-menu-reduzido {
    margin: 0;
    width: 100vw;
    height: 90vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .tituloMenuReduzido {
    width: 120px;
    height: 23px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;

    color: #ffffff;


    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .todosTitulos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 40px;
    gap: 40px;

    position: absolute;
    width: 315px;
    height: 243px;
    left: calc(50% - 315px / 2);
    top: 20px;
  }

  .setaMenuReduzido {
    position: absolute;
    left: 287px;
  }

  .linha {
    position: absolute;
    width: 315px;
    height: 1px;
    margin-top: 23.5px;
    background: #ffffff;
  }

  #traducao {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 20px;
    gap: 20px;

    position: absolute;
    width: 89px;
    height: 28px;
    left: calc(50% - 89px / 2 - 8px);
    bottom: 93px;
  }

  #social-links-menu-reduzido {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;
    grid-gap: 20px;
    gap: 20px;

    position: absolute;
    width: 230px;
    height: 30px;
    left: calc(50% - 230px / 2 - 7.5px);
    bottom: 43px;
  }
  .px-5-nav {
    padding-left: 3rem;
    padding-right: 1rem;
  }
}

@media (min-width: 475px) and (max-width: 991px) {
  #menuReduzido {
    width: 40px;
    height: 40px;
    display: block !important;
  }
  .px-5-nav {
    padding-left: 3rem;
    padding-right: 2rem;
  }
  .open,
  .open > .navbar {
    background-color: #02010a;
  }
  .open #logo-menu-nav-top {
    margin-left: -18px !important;
  }

  #reduzido {
    width: 100vw;
  }
  .yuo{
    margin: 0;
    height: 85vh !important;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #nav-ul-menu-reduzido {
    width: 100vw;
    
    background: rgba(2, 1, 10, 1);
    position: relative;

    
  }
  .tituloMenuReduzido {
    width: 192px;
    height: 38px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .todosTitulos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 40px;
    gap: 40px;

    position: absolute;
    width: 774px;
    height: 335px;
    left: calc(50% - 774px / 2);
    top: 50px;
  }

  .setaMenuReduzido {
    position: absolute;
    left: 745px;
  }

  .linha {
    position: absolute;
    width: 774px;
    height: 1px;
    margin-top: 23.5px;
    background: #ffffff;
  }

  #traducao {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 30px;
    gap: 30px;

    position: absolute;
    width: 122px;
    height: 38px;
    left: calc(50% - 122px / 2 - -14.5px);
    bottom: 113px;
  }

  #social-links-menu-reduzido {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;
    grid-gap: 30px;
    gap: 30px;

    position: absolute;
    width: 320px;
    height: 40px;
    left: calc(50% - 320px / 2);
    bottom: 43px;
  }
}

@media (min-width: 2559px) {
  .px-5-nav {
    padding-left: 600px;
    padding-right: 600px;
  }

  #menuReduzido {
    display: none;
  }
}

.translate{
  display: flex;
  
  
}
.translate span {
  width: 21px;
  height: 19px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  

  display: flex;
  align-items: center;
  letter-spacing: 0.07em;

  color: #ffffff;

  
  margin-left: 15px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.tecnologias {
  width: 1366px;
  height: 600px;
  position: relative;
  margin: auto;
}

#Tec-p {
  position: absolute;
  top: 200px;
  left: 154px;
  width: 1057px;
  height: 60px;
  margin: auto;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 60px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #1f1f1f;
}

.section-tec{
  position: absolute;
  top: 310px;
}

.container-tec-A {
  display: flex;
  margin-left: 100px;
  
}
.container-tec-B {
  display: flex;
  margin-left: 100px;
  margin-top: 50px;
  
}
.tecnologias-svg {
  width: 80px;
  height: 80px;
  margin-left: 50px;
}

#tec-svg-1 {
  margin-left: 40px;
}
#tec-svg-2 {
  margin-left: 60px;
}

.tecB {
  width: 1166px;
  height: 80px;
  margin: auto;
  margin-top: 50px;
}

#bloco-tec {
  position: absolute;
  width: 535px;
  height: 100px;
  right: 0px;
  top: 0px;
  background: #c2dfe3;
}

@media (min-width: 320px) and (max-width: 360px) {
  .tecnologias {
    width: 360px;
    height: 420px;
    position: relative;
    margin: auto;
  }

  #Tec-p {
    position: absolute;
    width: 300px;
    height: 138px;
    left: calc(50% - 300px / 2 + 0.5px);
    top: 50px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

  .container-tec-A {
    display: flex;
    margin-left: 30px;
    flex-direction: column-reverse;
    
  }
  .container-tec-B {
    display: flex;
    margin-top: 10px;
    margin-left: 30px;
    flex-direction: column;

  }

  .tecnologias-svg {
    width: 40px;
    height: 40px;
    margin-left: 30px;
    display: flex;
    
  }

  .tecA1{
    width: 300px;
    display: flex;
  }
  .tecA2{
    width: 300px;
    display: flex;
       
  }

  .tecB1{
    width: 315px;
    display: flex;
  }
  .tecB2{
    width: 315px;
    margin-top: 10px;
    display: flex;
  }

  .section-tec{
    width: 360px;
    position: absolute;
    top: 213px;
  }

  #tec-svg-1 {
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
  #tec-svg-2 {
    width: 40px;
    height: 40px;
    margin-left: 30px;
  }

  .tecB {
    width: 315px;
    height: 100px;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
  }
  #seta-tec {
    display: none;
  }

  #bloco-tec {
    position: absolute;
    width: 180px;
    height: 25px;
    right: 0px;
    top: 0px;
    background: #c2dfe3;
  }
}

@media (min-width: 361px) and (max-width: 769px) {
  .tecnologias {
    width: 100vw;
    height: 415px;
    position: relative;
    margin: auto;
  }

  #Tec-p {
    position: absolute;
    width: 300px;
    height: 138px;
    left: calc(50% - 300px / 2 + 0.5px);
    top: 50px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

  .container-tec-A {
    display: flex;
    margin-left: 30px;
    flex-direction: column-reverse;
    
  }
  .container-tec-B {
    display: flex;
    margin-top: 10px;
    margin-left: 30px;
    flex-direction: column;

  }

  .tecnologias-svg {
    width: 40px;
    height: 40px;
    margin-left: 30px;
    display: flex;
    
  }

  .tecA1{
    width: 315px;
    display: flex;
  }
  .tecA2{
    width: 315px;
    display: flex;
       
  }

  .tecB1{
    width: 315px;
    display: flex;
  }
  .tecB2{
    width: 315px;
    margin-top: 10px;
    display: flex;
  }

  .section-tec{
    width: 375px;
    position: absolute;
    top: 213px;
    left: calc(50% - 300px/2 + -35.5px);
  }

  #tec-svg-1 {
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
  #tec-svg-2 {
    width: 40px;
    height: 40px;
    margin-left: 30px;
  }

  .tecB {
    width: 315px;
    height: 100px;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
  }
  #seta-tec {
    display: none;
  }

  #bloco-tec {
    position: absolute;
    width: 180px;
    height: 25px;
    right: 0px;
    top: 0px;
    background: #c2dfe3;
  }
}

@media (min-width: 770px) and (max-width: 1009px) {
  .tecnologias {
    width: 100vw;
    height: 460px;
    position: relative;
    margin: auto;
  }

  #Tec-p {
    position: absolute;
    width: 700px;
    height: 120px;
    left: 100px;
    top: 100px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 60px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

  .section-tec{
    position: absolute;
    top: 270px;
  }

  .container-tec {
    display: flex;
    flex-direction: column;
  }

  .tecA {
    width: 734px;
    height: 50px;
    margin: auto;
    margin-top: 100px;
  }

  .tecnologias-svg {
    width: 50px;
    height: 50px;
    margin-left: 30px;
  }

  #tec-svg-1 {
    width: 50px;
    height: 50px;
    margin-left: 30px;
  }
  #tec-svg-2 {
    width: 50px;
    height: 50px;
    margin-left: 30px;
  }

  .tecB {
    width: 734px;
    height: 50px;
    margin: auto;
    margin-top: 50px;
  }
  #seta-tec {
    width: 215.52px;
    height: 158.13px;
    right: 10px;
    top: 0px;
  }

  #bloco-tec {
    position: absolute;
    width: 300px;
    height: 50px;
    right: 0;
    top: 0px;
    background: #c2dfe3;
  }
}

@media (min-width: 1367px) {
  #seta-tec {
    display: none;
  }
  #Tec-p {
    position: absolute;
    top: 200px;
    left: 154px;
    width: 1057px;
    height: 60px;
    margin: auto;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 60px;

    text-align: center;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

 .section-tec{
  position: absolute;
  top: 310px;
 }


  .tecA {
    margin-top: 310px;
  }
  #bloco-tec {
    position: absolute;
    width: 535px;
    height: 100px;
    left: 900px;
    right: 0px;
    top: 0px;
    background: #c2dfe3;
  }
}

.fundoContato {
  width: 1366px;
  height: 733px;
  position: relative;
  background: #c2dfe3;

  margin: auto;
}

.fale img {
  width: 203.96px;
  height: 193.3px;
  position: absolute;
  left: 25px;
  top: -10.86px;
}
.fale h3 {
  position: absolute;
  width: 381px;
  height: 51px;
  left: calc(50% - 340px / 2 - 248px);
  top: 100px;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;

  /* identical to box height */

  color: #8f53a1;
}

.contact {
  width: 966px;
  height: 400px;
  top: 226px;
  left: 200px;
  display: flex;
  justify-content: space-between;
  position: absolute;
}

.entrada {
  background-color: #c2dfe3;
  width: 470.5px;
  height: 60px;
  border: 2px solid #8f53a1;
  margin-top: 25px;
  color: #8f53a1;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.inputEntrada {
  width: 440px;
  height: 100%;
  background-color: #c2dfe3;
  outline: none;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  margin-left: 15px;
  color: #8f53a1;
}

input::-webkit-input-placeholder {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #8f53a1;
}

input::placeholder {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #8f53a1;
}

#erro {
  font-family: "Work Sans";
  color: red;
  font-size: 18px;
  margin-left: 15px;
}
textarea::-webkit-input-placeholder {
  color: #8f53a1;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
textarea::placeholder {
  color: #8f53a1;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

textarea::-webkit-scrollbar {
  width: 7px;
  height: 50px;
  /* width of the entire scrollbar */
}

textarea::-webkit-scrollbar-track {
  background: white;
  
}

textarea::-webkit-scrollbar-thumb {
  background-color: #8f53a1;

  
}

#mensagem {
  border: 2px solid #8f53a1;
  background-color: #c2dfe3;
  width: 470.5px;
  height: 230px;
  margin-top: 25px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  color: #8f53a1;
}

#textarea {
  background-color: #c2dfe3;
  width: 440.5px;
  height: 185px;
  margin-top: 10px;
  margin-left: 10px;
  outline: none;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  color: #8f53a1;
  padding: 10px;
  padding-bottom: 0;
  resize: none;
}

#fundoContact{
  background-color: #c2dfe3;
}
.fundoContact{
  background-color: #c2dfe3;
  width: 100vw;
}


#botao {
  width: 200px;
  height: 60px;
  z-index: 999;
  background: #8f53a1;
  color: white;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  flex: none;
  order: 2;
  flex-grow: 0;
  position: absolute;
  top: 586px;
  left: 966px;
  transition: -webkit-transform 100ms ease-out;
  transition: transform 100ms ease-out;
  transition: transform 100ms ease-out, -webkit-transform 100ms ease-out;
}

#fundoBotao {
  width: 200px;
  height: 60px;
  background-color: white;
  position: absolute;
  top: 596px;
  left: 976px;
}

#botao:active {
  /* transform: translateY(10px); */
  -webkit-transform: translate(10px, 10px);
          transform: translate(10px, 10px);
}

#seta {
  position: absolute;
  width: 353px;
  height: 259px;
  left: 988px;
  top: 525px;
}

.lottiePumpkin {
  background: #c2dfe3;
  position: relative;
  width: 1366px;
  height: 726px;
  margin: auto;

}

.lottiePumpkin svg {
  position: absolute;
  width: 695px !important;
  height: 227px !important;
  left: 336px;
  top: 250px;
}
.lottiePumpkin2{
  display: none;
}

#iconEnviar {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 85px;
  top: 15px;

  background: #ffffff;
  border-radius: 15px;
}

@media (min-width: 320px) and (max-width: 360px) {
  .fundoContato {
    width: 100%;
    max-width: 360px;
    height: 860px;
    position: relative;
    background: #c2dfe3;
    
    margin: auto;
  }
  #fundoContact{
    background-color: #c2dfe3;
  }
  .fundoContact{
    background-color: #c2dfe3;
    width: 100vw;
  }

  .fale img {
    width: 53.75px;
    height: 51.21px;
    position: absolute;
    left: 6.59px;
    top: -6px;
  }
  .fale h3 {
    position: absolute;
    width: 272px;
    height: 41px;
    left: calc(50% - 238px / 2);
    top: 29px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #8f53a1;
    
  }

  .contact {
    width: 300px;
    height: 610px;
    top: 116px;
    left: 0px;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
  }

  .entrada {
    background-color: #c2dfe3;
    width: 300px;
    height: 60px;
    border: 2px solid #8f53a1;
    margin-top: 25px;
    color: #8f53a1;
    padding: 5px;
  }

  .entrada:first-child {
    margin: 0;
  }

  .inputEntrada {
    width: 270px;
    height: 50px;
    background-color: #c2dfe3;
    outline: none;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-left: 15px;
    color: #8f53a1;
  }

  input::-webkit-input-placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  input::placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  #erro {
    font-family: "Work Sans";
    color: red;
    font-size: 18px;
  }
  textarea::-webkit-input-placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  textarea::placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  textarea::-webkit-scrollbar {
    width: 7px;
    height: 50px;
    /* width of the entire scrollbar */
  }

  textarea::-webkit-scrollbar-track {
    background: white;
    /* color of the tracking area */
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #8f53a1;
  }

  #mensagem {
    border: 2px solid #8f53a1;
    background-color: #c2dfe3;
    width: 300px;
    height: 180px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
  }

  #textarea {
    background-color: #c2dfe3;
    width: 290px;
    height: 138px;
    margin: 0;
    margin-top: 10px;

    outline: none;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
    padding: 10px;
    resize: none;
  }

  #botao {
    width: 300px;
    height: 60px;
    z-index: 999;
    background: #8f53a1;
    color: white;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    flex: none;
    order: 2;
    flex-grow: 0;
    position: absolute;
    top: 756px;
    left: 30px;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  #fundoBotao {
    width: 300px;
    height: 60px;
    background-color: white;
    position: absolute;
    top: 756px;
    left: 40px;
  }

  #botao:active {
    transition: width 1000s, height 100000s;
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }

  #seta {
    display: none;
  }
  .lottiePumpkin {
    display: none;
  }
  .lottiePumpkin2 {
    background: #c2dfe3 !important;
    position: relative !important;
    width: 360px !important;
    height: 950px;
    margin: auto ;
    margin-top: 120px !important;
    z-index: 999;
    display: block;
  }
  .lottiePumpkin2 svg {
    width: 98% !important;
    height: 35% !important;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    content-visibility: visible !important;
    top: 102.47px  !important;
    position: absolute;
    display: block;
  }
  #agradecimentoContato{
   
    width: 300px;
    height: 200px;
    margin-inline: 30px;
    position: absolute;
    top: 450px;
    
}

  #agradecimentoContato h1{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 51px;
    
    align-items: center;
    text-align: center;
    
    color: #8F53A1;
  }
  #agradecimentoContato h2{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 51px;
   
    align-items: center;
    text-align: center;
    
    color: #8F53A1;
  }
 
  
  .lottiePumpkin svg {
    display: none;
  }

  #iconEnviar{
    position: absolute;
    width: 40px;
    height: 40px;
    left: 130px;
    top: 10px;
    background: #ffffff;
    border-radius: 25px;
  }

  
}

@media (min-width: 361px) and (max-width: 629px) {
  .fundoContato {
    width: 100vw;
    height: 950px;
    position: relative;
    background: #c2dfe3;
    top: 30px;
    margin: auto;
  }
  #fundoContact{
    background-color: #c2dfe3;
  }
  .fundoContact{
    background-color: #c2dfe3;
    width: 100vw;
  }

  .fale img {
    width: 95.56px;
    height: 91.03px;
    position: absolute;
    left: 11.71px;
    top: -7.23px;
  }
  .fale h3 {
    position: absolute;
    width: 223px;
    height: 41px;
    left: calc(50% - 124px / 2);
    top: 29px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #8f53a1;
   
}

 
  

  .contact {
    max-width: 315px;
    width: 100%;
    height: 610px;
    top: 116px;
    left: 0px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    margin:auto;
  }

  .entrada {
    background-color: #c2dfe3;
    max-width: 315px;
    width: 100%;
    height: 60px;
    border: 2px solid #8f53a1;
    margin-top: 25px;
    color: #8f53a1;
    padding: 5px;
  }

  .entrada:first-child {
    margin: 0;
  }

  .inputEntrada {
    max-width: 290px;
    width: 100%;
    height: 50px;
    background-color: #c2dfe3;
    outline: none;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-left: 15px;
    color: #8f53a1;
  }

  input::-webkit-input-placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  input::placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  #erro {
    font-family: "Work Sans";
    color: red;
    font-size: 18px;
  }
  textarea::-webkit-input-placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  textarea::placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  textarea::-webkit-scrollbar {
    width: 7px;
    height: 50px;
    /* width of the entire scrollbar */
  }

  textarea::-webkit-scrollbar-track {
    background: white;
    /* color of the tracking area */
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #8f53a1;

    /* color of the scroll thumb */
    /* creates padding around scroll thumb */
  }

  #mensagem {
    border: 2px solid #8f53a1;
    background-color: #c2dfe3;
    width: 315px;
    height: 180px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
  }

  #textarea {
    background-color: #c2dfe3;
    width: 290px;
    height: 138px;
    margin: 0;
    margin-top: 10px;

    outline: none;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
    padding: 10px;
    resize: none;
  }

  #botao {
    width: 315px;
    height: 60px;
    z-index: 999;
    background: #8f53a1;
    color: white;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    flex: none;
    order: 2;
    flex-grow: 0;
    position: absolute;
    top: 756px;
    left: 0px;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    right: 0px;
    margin: auto;
  }

  #fundoBotao {
    width: 315px;
    height: 60px;
    background-color: white;
    position: absolute;
    top: 756px;
    right: 0px;
    left: 20px;
    margin: auto;
  }

  #botao:active {
    transition: width 1000s, height 100000s;
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }

  #seta {
    display: none;
  }

  .lottiePumpkin {
    display: none;
  }
  .lottiePumpkin2 {
    background: #c2dfe3;
    position: relative;
    width: 375px;
    height: 950px;
    margin: auto;
    display: block;
  }
  .lottiePumpkin2 svg {
    width: 98% !important;
    height: 35% !important;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    content-visibility: visible;
    top: 102.47px;
    position: absolute;
    display: block;
  }
  #agradecimentoContato{
   
    width: 300px;
    height: 200px;
    margin-inline: 40px;
    position: absolute;
    top: 450px;
    
}

  #agradecimentoContato h1{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 51px;
    
    align-items: center;
    text-align: center;
    
    color: #8F53A1;
  }
  #agradecimentoContato h2{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 51px;
   
    align-items: center;
    text-align: center;
    
    color: #8F53A1;
  }
 
  
  .lottiePumpkin svg {
    display: none;
  }

  #iconEnviar{
    position: absolute;
    width: 40px;
    height: 40px;
    left: 140px;
    top: 10px;
    background: #ffffff;
    border-radius: 25px;
  }
}
@media (min-width: 629px) and (max-width: 789px) {
  .fundoContato {
    width: 100vw;
    height: 686px;
    position: relative;
    background: #c2dfe3;
    top: 100px;
    margin: auto;
  }
  #fundoContact{
    background-color: #c2dfe3;
  }
  .fundoContact{
    background-color: #c2dfe3;
    width: 100vw;
  }

  .fale img {
    width: 124.53px;
    height: 118.63px;
    position: absolute;
    left: 9.84px;

    top: -10.86px;
  }
  .fale h3 {
    position: absolute;
    width: 393px;
    height: 51px;
    left: 168px;
    top: 50px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #8f53a1;
   
  }

  .contact {
    max-width: 600px;
    width: 100%;
    height: 610px;
    top: 151px;
    left: 0px;
    right: 0;
    display: flex;

    position: absolute;
    margin: auto;
  }

  .entrada {
    background-color: #c2dfe3;
    width: 282.5px;

    height: 60px;
    border: 2px solid #8f53a1;
    margin-top: 25px;
    color: #8f53a1;
    padding: 5px;
  }

  .entrada:first-child {
    margin: 0;
  }

  .inputEntrada {
    max-width: 245px;
    width: 100%;
    height: 50px;
    background-color: #c2dfe3;
    outline: none;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-left: 15px;
    color: #8f53a1;
  }

  input::-webkit-input-placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  input::placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  #erro {
    font-family: "Work Sans";
    color: red;
    font-size: 18px;
  }
  textarea::-webkit-input-placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  textarea::placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  textarea::-webkit-scrollbar {
    width: 7px;
    height: 50px;
    /* width of the entire scrollbar */
  }

  textarea::-webkit-scrollbar-track {
    background: white;
    /* color of the tracking area */
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #8f53a1;

    /* color of the scroll thumb */
    /* creates padding around scroll thumb */
  }

  #mensagem {
    border: 2px solid #8f53a1;
    background-color: #c2dfe3;
    width: 284.5px;
    height: 230px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
  }

  #textarea {
    background-color: #c2dfe3;
    width: 266px;
    height: 187px;
    margin: 0;
    margin-top: 10px;

    outline: none;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
    padding: 10px;
    resize: none;
  }

  #botao {
    width: 200px;
    height: 60px;
    z-index: 999;
    background: #8f53a1;
    color: white;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    flex: none;
    order: 2;
    flex-grow: 0;
    position: absolute;
    top: 491px;
    left: 364px;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  #fundoBotao {
    width: 200px;
    height: 60px;
    background-color: white;
    position: absolute;
    top: 491px;
    left: 371px;
  }

  #botao:active {
    transition: width 1000s, height 100000s;
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
    background-image: url(/static/media/Check.16b9d0c3.svg);
  }

  #seta {
    display: none;
  }
  .lottiePumpkin {
    background: #c2dfe3;
    position: relative;
    width: 834px;
    margin: auto;
    height: 686px;
  }
  
  .lottiePumpkin svg {
    position: absolute;
    width: 645px !important;
    height: 227px !important;
    left: 130px;
    top: 230px;
  }

  .lottiePumpkin2{
    display: none;
  }
  .lottiePumpkin2 svg{
  display: none;
}
#agradecimentoContato{
  display: none;
}
}
@media (min-width: 790px) and (max-width: 1009px) {
  .fundoContato {
    width: 100vw;
    height: 686px;
    position: relative;
    background: #c2dfe3;
    top: 100px;
    margin: auto;
  }
  #fundoContact{
    background-color: #c2dfe3;
  }
  .fundoContact{
    background-color: #c2dfe3;
    width: 100vw;
  }

  .fale img {
    width: 124.53px;
    height: 118.63px;
    position: absolute;
    left: 9.84px;

    top: -10.86px;
  }
  .fale h3 {
    position: absolute;
    width: 393px;
    height: 51px;
    left: 168px;
    top: 50px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #8f53a1;
   
  }

  .contact {
    max-width: 734px;
    width: 100%;
    height: 610px;
    top: 151px;
    left: 0;
    display: flex;
    position: absolute;
    margin: auto;
    right: 0;
  }

  .entrada {
    background-color: #c2dfe3;
    width: 354.5px;

    height: 60px;
    border: 2px solid #8f53a1;
    margin-top: 25px;
    color: #8f53a1;
    padding: 5px;
  }

  .entrada:first-child {
    margin: 0;
  }

  .inputEntrada {
    max-width: 325px;
    width: 100%;
    height: 50px;
    background-color: #c2dfe3;
    outline: none;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-left: 15px;
    color: #8f53a1;
  }

  input::-webkit-input-placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  input::placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  #erro {
    font-family: "Work Sans";
    color: red;
    font-size: 18px;
  }
  textarea::-webkit-input-placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  textarea::placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  textarea::-webkit-scrollbar {
    width: 7px;
    height: 50px;
    /* width of the entire scrollbar */
  }

  textarea::-webkit-scrollbar-track {
    background: white;
    /* color of the tracking area */
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #8f53a1;

    /* color of the scroll thumb */
    /* creates padding around scroll thumb */
  }

  #mensagem {
    border: 2px solid #8f53a1;
    background-color: #c2dfe3;
    width: 354.5px;
    height: 230px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
  }

  #textarea {
    background-color: #c2dfe3;
    width: 290px;
    height: 187px;
    margin: 0;
    margin-top: 10px;

    outline: none;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
    padding: 10px;
    resize: none;
  }

  #botao {
    width: 200px;
    height: 60px;
    z-index: 999;
    background: #8f53a1;
    color: white;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    flex: none;
    order: 2;
    flex-grow: 0;
    position: absolute;
    top: 491px;
    left: 564px;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  #fundoBotao {
    width: 200px;
    height: 60px;
    background-color: white;
    position: absolute;
    top: 491px;
    left: 575px;
  }

  #botao:active {
    transition: width 1000s, height 100000s;
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
    background-image: url(/static/media/Check.16b9d0c3.svg);
  }

  #seta {
    display: none;
  }
  .lottiePumpkin {
    background: #c2dfe3;
    position: relative;
    width: 834px;
    margin: auto;
    height: 686px;
  }
  
  .lottiePumpkin svg {
    position: absolute;
    width: 645px !important;
    height: 227px !important;
    left: 130px;
    top: 230px;
  }

  .lottiePumpkin2{
    display: none;
  }
  .lottiePumpkin2 svg{
  display: none;
}
#agradecimentoContato{
  display: none;
}
}
#header-arrow-down {
  cursor: pointer;
}

.background {
  margin: 0;
  padding: 0;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;

  background-image: url(/static/media/telaDesktop.8006cd78.png);
  margin: 0;
  height: 90vh;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;
}

#tecnologiaLogo {
  position: absolute;
  width: 421.83px;
  height: 277.85px;
  left: calc(50% - 421.83px / 2 + 286.91px);
  top: calc(50% - 277.85px / 2 + 51.92px);
}

#social-links {
  position: absolute;
  color: #fff;
  right: 0px;
  bottom: 20px;
}
#icones {
  margin-left: 10px;
}

@media (min-width: 320px) and (max-width: 360px) {
  .background {
    margin: 0;
    padding: 0;
    background-image: url(/static/media/telaAndroid.ee386022.png);
    width: 100vw;
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 90vh;
    

    position: relative;
  }

  #tecnologiaLogo{
    position: absolute;
width: 257.54px;
height: 169.64px;
left: calc(50% - 257.54px/2 + 20.77px);
top: calc(50% - 169.64px/2 + 61.82px);
  }



  .fundoPrincipalHome {
    margin: 0;
    max-width: 360px;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #header-social-icons-logo {
    position: absolute;
    color: white;
    right: 30.46px;
    bottom: 175px;
  }
  #header-social-icons-logo img {
    width: 257.54px;
    height: 169.64px;
  }

  #header-arrow-down {
    z-index: 999;
  }

  #icones {
    display: none;
  }
}
@media (min-width: 361px) and (max-width: 777px) {
  .background {
    margin: 0;
    padding: 0;
    background-image: url(/static/media/telaIphone.0233f258.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 90vh;
    position: relative;
  }

  #tecnologiaLogo {
    position: absolute;
    width: 257.54px;
    height: 169.64px;
    left: calc(50% - 257.54px / 2 + 28.27px);
    top: calc(50% - 169.64px / 2 + 50.32px);
  }

  .fundoPrincipalHome {
    margin: 0;
    max-width: 475px;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #header-social-icons-logo {
    position: absolute;
    color: white;
    right: 30px;
    margin-bottom: 25px;
  }

  #header-arrow-down {
    z-index: 999;
  }
  #icones {
    display: none;
  }
}
@media (min-width: 778px) and (max-width: 1009px) {
  
  .fundoPrincipalHome {
    margin: 0;
    max-width: 834px;
    width: 100%;
    height: 90vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #tecnologiaLogo {
    position: absolute;
    width: 257.54px;
    height: 169.64px;
    left: calc(50% - 257.54px / 2 + 175.17px);
    top: calc(50% - 169.64px / 2 + 50.32px);
  }

  .header-social-icons-logo {
    position: absolute;
    width: 257.54px;
    height: 169.64px;
    left: calc(50% - 12.5px);
    top: calc(50% - 169.64px / 2 + 50.32px);
  }

  #social-links {
    display: none;
  }

  #header-arrow-down {
    display: none;
  }

  #icones {
    display: none;
  }
}
@media (min-width: 1367px) {
  
  #tecnologiaLogo {
    position: absolute;
    width: 421.83px;
    height: 277.85px;
    left: calc(50% - 421.83px / 2 + 286.92px);
    top: calc(50% - 277.85px / 2 + 51.92px);
  }
  .fundoPrincipalHome {
    margin: 0;
    max-width: 2560px;

    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  #social-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    position: absolute;
    width: 190px;
    height: 30px;
    left: calc(50% - 190px / 2 + 558px);
    bottom: 30px;
  }
  #icones {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border: none;
  }
}


.unsubFundo{
  width: 100vw;
  height: 100vh;
  background-color:#8F53A1;
}

.unsubFundo h3{
max-width: 520px;
width: 100%;
height: 48px;
margin-left: 100px;
top: 200px;
position:absolute;
font-family: 'Work Sans';
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 48px;
/* identical to box height, or 150% */


color: #FFFFFF;
}

.unsubFundo p{
  max-width: 520px;
  width: 100%;
  height: 88px;
  left: 100px;
  top: 298px;
  position:absolute;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 44px;
  /* or 183% */
  
  
  color: #FFFFFF;
}


@media (max-width: 540px){
  .unsubFundo h3{
    max-width: 230px;
    width: 100%;
    height: 48px;
    left: 30px;
    top: 200px;
    margin:0;
    position:absolute;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height, or 150% */
    
    
    color: #FFFFFF;
    }
    
    .unsubFundo p{
      max-width: 290px;
      width: 100%;
      height: 88px;
      left: 30px;
      top: 320px;
      position:absolute;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 44px;
      /* or 183% */
      
      
      color: #FFFFFF;
    }
}


:root {
  --laranja1: #fd9526;
  --cinza1: #f2f2f2;
  --roxo1: #7a00fd;
  --roxo2: #2a1244;
  --verde1: #5be33a;
  --azul1: #26cbfd;
  --vermelho1: #fd2626;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none !important;
}

body {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translateZ(0.1px);
          transform: translateZ(0.1px);
}

#nav-ul > li > .nav-active {
  color: #fd9526;
  color: var(--laranja1);
}

#nav-ul > li > .nav-active::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #fd9526;
  background: var(--laranja1);
}

#drop-idioma {
  background-color: transparent !important;
}

.btn-secondary {
  background-color: transparent !important;
  color: #fd9526;
  color: var(--laranja1);
  border: none;
}

.show > .btn-secondary.dropdown-toggle {
  background-color: transparent !important;
  color: #fd9526;
  color: var(--laranja1);
  border: none;
}

.btn-secondary:hover {
  color: #fd9526;
  color: var(--laranja1);
}

#container-nav {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px;
  z-index: 9999;
}






.underline-animation:not(.nav-active, .underline-animation-left)::after {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

@media (hover) {
  .underline-animation:hover::after,
  .underline-animation:focus::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

.underline-animation-left {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 0 !important;
  color: black !important;
}

.underline-animation-left::after {
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: scaleX(0.45);
          transform: scaleX(0.45);
}

.underline-roxo::after {
  background: #7a00fd;
  background: var(--roxo1);
}

.underline-verde::after {
  background: #5be33a;
  background: var(--verde1);
}

.underline-azul::after {
  background: #26cbfd;
  background: var(--azul1);
}

.underline-vermelho::after {
  background: #fd2626;
  background: var(--vermelho1);
}

.nav-collapse-fix {
  display: flex !important;
  justify-content: center !important;
}

@media (min-width: 992px) {
  .nav-collapse-fix {
    display: none !important;
  }
}

/* Index */

#index-header {
  margin: 0;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

#index-header-text > h1 {
  font: St Transmission;
  font-size: calc(50px + 3vw);
  letter-spacing: 0px;
  color: #ffffff;
}

#header-arrow-down {
  position: absolute;
  bottom: 20px;
  left: calc(50% - 12.5px);
}

#linkEmail > i {
  font-size: 2rem;
  resize: both;
}

#header-social-icons {
  position: absolute;
  color: white;
  right: 20px;
  bottom: 20px;
}

#header-language-icons {
  position: absolute;
  color: white;
  left: 30px;
  bottom: 20px;
}

.language-icons-header {
  margin-left: 1rem;
  color: #fff;
}

.language-icons-header:hover {
  cursor: pointer;
}

.social-icons-footer {
  color: #707070;
}

.social-icons-footer:hover {
  color: #2a1244;
  color: var(--roxo2);
  cursor: pointer;
}

.social-icons-header {
  margin-left: 1rem;
  color: #fff;
}

.social-icons-header:hover {
  cursor: pointer;
}

.social-icons-container .social-icons-header,
.social-icons-footer,
.language-icons-header {
  transition: all 0.5s;
}

.social-icons-container .social-icons-header:hover,
.social-icons-footer:hover,
.language-icons-header:hover {
  -webkit-transform: scale(1.25);
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
}

.direita span {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  color: #8f53a1;
}

#index-section2-title {
  text-align: center;
  align-items: center;
}

.index-section2-card-title > svg {
  color: #fd9526;
  color: var(--laranja1);
}

.icone {
  margin-bottom: 20px;
  margin-left: 60px;
  padding: 0;
}

.index-section2-card-title > span {
  width: 308.67px;
  height: 160px;

  font-size: 26px;
  font-family: Gobold;
  font-weight: bold;
  letter-spacing: 0px;
  color: #1c1c1c;
}

.index-section2-card-content > span {
  font-size: 20px;
  font-family: St Transmission;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
}

.border-bottom-laranja {
  border-bottom: 8px solid #fd9526;
  border-bottom: 8px solid var(--laranja1);
}

/* Bloco 3 */

.index-section3-row1 {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.index-section3-content > h1 {
  font-size: calc(40px + 2.3vw);
  font-family: Gobold;
  font-weight: bold;
  letter-spacing: 0px;
  color: #1c1c1c;
}

.index-section3-content > h2 {
  font-size: calc(40px + 2.3vw);
  font-family: Gobold Cuts;
  /* font-weight: bold; */
  letter-spacing: 0px;
  color: #1c1c1c;
}

.index-section3-content > span {
  font-size: 25px;
  font-family: St Transmission;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
}

#index-section3-title {
  max-width: 415px;
}

.index-section3-card > img {
  max-width: 100%;
  object-fit: cover;
}

.index-section3-card > h2 {
  letter-spacing: 0px;
  color: #1c1c1c;
  font-size: 30px;
  font-family: Gobold;
  font-weight: bold;
}

.index-section3-card > span {
  letter-spacing: 0px;
  color: grey;
  font-weight: bold;
  font-size: 18px;
  font-family: St Transmission;
}

.index-section3-card > a {
  font-size: 23px;
  font-family: "Roboto", sans-serif;
  /* font: normal normal normal 23px/35px Roboto; */
  letter-spacing: 0px;
  color: #000000;
}

.box-shadow {
  box-shadow: 0px 3px 30px #00000038;
}

/* FOOTER CONTATO */

#footer-contato-titulo {
  font: normal normal normal 30px Gobold;
  font-weight: bold;
  letter-spacing: 0px;
  color: #1c1c1c;
}

.footer-contato-conteudo {
  margin-top: 2rem;
}

.footer-contato-conteudo > h2 {
  font-size: 28px;
  font-family: St Pro Text;
  letter-spacing: 0px;
  color: #1c1c1c;
}

.footer-contato-conteudo > span {
  font-size: 15px;
  font-family: St Pro Text;
  letter-spacing: 0px;
  color: #1c1c1c;
}

.footer-contato-conteudo > a {
  font-size: 23px;
  font-family: "Roboto", sans-serif;
  /* font: normal normal normal 23px/35px Roboto; */
  letter-spacing: 0px;
  color: #000000;
}

.footer-contato-conteudo-link-span {
  font-size: 23px;
  font-family: "Roboto", sans-serif;
  /* font: normal normal normal 23px/35px Roboto; */
  letter-spacing: 0px;
  color: #000000;
}

.footer-contato-conteudo > input {
  border-radius: 37px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  width: 100%;
  max-width: 400px;
}

.footer-contato-conteudo > input::-webkit-input-placeholder {
  color: #c3c3c3;
}

.footer-contato-conteudo > input::placeholder {
  color: #c3c3c3;
}

.footer-content > img {
  width: 100%;
  max-width: 220px;
}

.footer-content > h4 {
  font-size: 14px;
  font-family: St Pro Text;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
}

.footer-content > ul {
  padding: 0px;
}

.footer-content > ul > li {
  list-style: none;
  font-size: 14px;
  font-family: St Pro Text;
  letter-spacing: 0px;
  color: #747474;
 
}

.footer-content > ul > li > a {
  list-style: none;
  font-size: 14px;
  font-family: St Pro Text;
  letter-spacing: 0px;
  color: #747474;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

#form_unform {
  max-height: 50px !important;
  height: 100% !important;
}
#teste {
  padding: 0;
}

