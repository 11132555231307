@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url("../db.onlinewebfonts/fonts.css");
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
/* PRODUTOS E EXPERIÊNCIAS*/

.containerBloco2 {
  width: 1366px;
  position: relative;
}

.superior {
  height: 100px;
    width: 535px;
    /* margin-left: 831px; */
    position: absolute;
    background: #c2dfe3;
    /* bottom: 145px; */
    /* top: 0; */
    right: 0;
}

#blocoDois {
  
  display: flex;
  position: relative;
  top: 112px;
}
.col-A {
  width: 500px;
  height: 666px;

  margin-left: -72px;
}

.col-B {
  width: 700px;
  height: 666px;
  margin-top: 100px;
  margin-left: 150px;
}

.col-B h2 {
  width: 670px;
  height: 164px;
  margin: 0;

  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 82px;
  letter-spacing: 0.02em;

  color: #02010a;
}

.col-B p {
  margin: 0;
  margin-top: 50px;

  width: 666px;
  height: 352px;
  left: 0px;
  top: 214px;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 44px;
}
#logo {
  margin-left: -482px;
  margin-top: -419px;
}

@media (min-width: 320px) and (max-width: 360px) {
  .containerBloco2 {
    width: 100vw;
  }
  .superior {
    width: 180px;
    height: 25px;
    right: 0;
    position: absolute;
    background: #c2dfe3;
  }

  #blocoDois {
    width: 100vw;
    max-width: 475px;
    height: 1120px;
    display: flex;
    margin-top: 30px;
    flex-direction: column;
  }
  .col-A {
    width: 100%;
    height: 300px;
    max-width: 360px;
  }

  #logo {
    width: 600px;
    margin-left: -244px;
    margin-top: -319px;
  }
  .col-B {
    width: 332px;
    height: 717px;
    margin: 0;
    margin-top: 25px;

    margin-inline: 30px;
  }

  .col-B h2 {
    width: 300px;
    height: 138px;
    
    margin: 0;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.02em;

    color: #02010a;
  }

  .col-B p {
    width: 315px;
    height: 504px;
    margin-top: 25px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    margin: 0;

    margin-top: 25px;

    color: #02010a;
  }
}

@media (min-width: 361px) and (max-width: 730px) {
  .containerBloco2 {
    width: 100vw;
  }
  .superior {
    width: 180px;
    height: 25px;
    right: 0px;
    position: absolute;
    background: #c2dfe3;
    
  }

  #blocoDois {
    width: 100vw;
    
    height: 1161px;
    display: flex;
    flex-direction: column;
  }
  .col-A {
    width: 100%;
    height: 300px;
    max-width: 360px;
  }

  #logo {
    width: 600px;
    margin-left: -244px;
    margin-top: -320px;
  }
  .col-B {
    width: 332px;
    height: 717px;
    margin: 0;
    margin-top: 25px;

    margin-inline: 30px;
  }

  .col-B h2 {
    width: 300px;
    height: 138px;
    /* margin-top: 25.47px; */
    margin: 0;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.02em;

    color: #02010a;
  }

  .col-B p {
    width: 315px;
    height: 504px;
    margin-top: 25px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    margin: 0;

    margin-top: 25px;

    color: #02010a;
  }
}


@media (min-width: 731px) and (max-width: 1009px) {
  .containerBloco2 {
    margin: 0;
    padding: 0;
    flex: 1;
    width: 100vw;
    position: relative;
  }

  .superior {
    width: 300px;
    height: 50px;
    position: absolute;
    right: 0;
    
    background: #c2dfe3;
  }
  #blocoDois {
    width: 100vw;
    height: 804px;
    
  }
  .col-A {
    width: 300px;
    height: 399.6px;
  }
  #logo {
    max-width: 610px;
    /* height: 399.6px; */
    margin-top: 180px;
    margin-left: -248px;
  }

  .col-B {
    max-width: 530px;
    height: 816px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
    margin-left: 120px;
  }
  .col-B h2 {
    width: 484px;
    height: 246px;
    margin: 0;
  }
  .col-B p {
    max-width: 484px;
    height: 528px;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;

    color: #02010a;
  }
}


@media (min-width: 1552px) {
  .containerBloco2 {
    width: 1500px;
    margin: auto;
  }
  .superior {
    margin-left: 966px;
    background: #c2dfe3;
  }
  #blocoDois {
    overflow: hidden;
    width: 1500px;
    height: 750px;
    margin: auto;
    display: flex;
  }
  .col-A {
    height: 757px;
    width: 741px;
  }
  #logo {
    margin-left: 76px;
    height: 757px;
    width: 741px;
    margin-top: -102px;
  }

  .col-B p {
    margin-top: 50px;
  }
}


/*SERVIÇOS*/

.servicos {
  width: 1184px;
  height: 1599px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 150px;
  margin-top: 50px;
}

.imagemGrid {
  margin-top: 53.26px;
  margin-left: 50px;
}

.tituloGrid {
  width: 320px;
  height: 41px;
  margin-top: 22.88px;
  margin-left: 50px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 41px;
  font-weight: bold;
  letter-spacing: 0.02em;

  color: #1f1f1f;
}

.paragrafoGrid {
  width: 313.5px;
  /* height: 120px; */

  font-family: 'Work Sans';
  font-style: normal !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  color: #1f1f1f;

  margin-left: 50px;
}

@media (min-width: 320px) and (max-width: 630px) {
  .servicos {
    width: 100%;
    max-width: 480px;
    height: 3140px;
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .imagemGrid {
    width: 80px;
    height: 80px;
    margin: 0;
  }
  .tituloGrid {
    width: 315px;
    height: 41px;
    margin: 0;
    margin-top: 21px;
    font-weight: 500;
    color: #1f1f1f;
    font-weight: bold;
  }
  .paragrafoGrid {
    width: 315px;

    margin: 0;
    margin-top: 20px;
    color: #1f1f1f;
  }

  .imagemGrid {
    margin-top: 25px;
  }

  .design {
    margin-inline: 30px;
  }
  #blocoSuperior {
    width: 180px;
    height: 25px;
    margin-left: 195px;

    background: #c2dfe3;
  }
}

@media (min-width: 631px) and (max-width: 772px) {
  .servicos {
    width: 100vw;
    height: 1983px;
    margin: 0px;
    padding: 4px;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-content: space-between;
  }

  .tituloGrid,
  .paragrafoGrid,
  .imagemGrid {
    margin: 0;
  }

  .imagemGrid {
    width: 144.98px;
    height: 156.52px;
  }
  .tituloGrid {
    margin-top: 20px;

    max-width: 313.5px;
    height: 41px;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 41px;
    /* identical to box height */
    font-weight: bold;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

  .paragrafoGrid {
    margin-top: 20px;

    max-width: 313.5px;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    color: #1f1f1f;
  }

  #blocoSuperior {
    width: 300px;
    height: 50px;
    margin-left: 534px;
  }
}

@media (min-width: 773px) and (max-width: 1009px) {
  .servicos {
    width: 100vw;
    height: 1983px;
    margin: 0px;
    margin-top: 100px;
    padding: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-content: space-between;
  }

  .tituloGrid,
  .paragrafoGrid,
  .imagemGrid {
    margin: 0;
  }

  .imagemGrid {
    width: 144.98px;
    height: 156.52px;
  }
  .tituloGrid {
    margin-top: 20px;

    max-width: 313.5px;
    height: 41px;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 41px;
    /* identical to box height */
    font-weight: bold;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

  .paragrafoGrid {
    margin-top: 20px;
    max-width: 313.5px;
    font-family: 'Work Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    color: #1f1f1f;
  }

  #blocoSuperior {
    width: 300px;
    height: 50px;
    margin-left: 534px;
  }
}

@media (min-width: 1367px) {
  .servicos {
    width: 1366px;

    margin: auto;
    margin-top: 100px;
    justify-content: center;
  }

  #img {
    display: none;
  }
}
