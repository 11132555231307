@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("../db.onlinewebfonts/fonts.css");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap");

#header-arrow-down {
  cursor: pointer;
}

.background {
  margin: 0;
  padding: 0;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;

  background-image: url(../../assets/Home/novaVersao/telaDesktop.png);
  margin: 0;
  height: 90vh;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;
}

#tecnologiaLogo {
  position: absolute;
  width: 421.83px;
  height: 277.85px;
  left: calc(50% - 421.83px / 2 + 286.91px);
  top: calc(50% - 277.85px / 2 + 51.92px);
}

#social-links {
  position: absolute;
  color: #fff;
  right: 0px;
  bottom: 20px;
}
#icones {
  margin-left: 10px;
}

@media (min-width: 320px) and (max-width: 360px) {
  .background {
    margin: 0;
    padding: 0;
    background-image: url(../../assets/Home/novaVersao/telaAndroid.png);
    width: 100vw;
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 90vh;
    

    position: relative;
  }

  #tecnologiaLogo{
    position: absolute;
width: 257.54px;
height: 169.64px;
left: calc(50% - 257.54px/2 + 20.77px);
top: calc(50% - 169.64px/2 + 61.82px);
  }



  .fundoPrincipalHome {
    margin: 0;
    max-width: 360px;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #header-social-icons-logo {
    position: absolute;
    color: white;
    right: 30.46px;
    bottom: 175px;
  }
  #header-social-icons-logo img {
    width: 257.54px;
    height: 169.64px;
  }

  #header-arrow-down {
    z-index: 999;
  }

  #icones {
    display: none;
  }
}
@media (min-width: 361px) and (max-width: 777px) {
  .background {
    margin: 0;
    padding: 0;
    background-image: url(../../assets/Home/novaVersao/telaIphone.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 90vh;
    position: relative;
  }

  #tecnologiaLogo {
    position: absolute;
    width: 257.54px;
    height: 169.64px;
    left: calc(50% - 257.54px / 2 + 28.27px);
    top: calc(50% - 169.64px / 2 + 50.32px);
  }

  .fundoPrincipalHome {
    margin: 0;
    max-width: 475px;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #header-social-icons-logo {
    position: absolute;
    color: white;
    right: 30px;
    margin-bottom: 25px;
  }

  #header-arrow-down {
    z-index: 999;
  }
  #icones {
    display: none;
  }
}
@media (min-width: 778px) and (max-width: 1009px) {
  
  .fundoPrincipalHome {
    margin: 0;
    max-width: 834px;
    width: 100%;
    height: 90vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #tecnologiaLogo {
    position: absolute;
    width: 257.54px;
    height: 169.64px;
    left: calc(50% - 257.54px / 2 + 175.17px);
    top: calc(50% - 169.64px / 2 + 50.32px);
  }

  .header-social-icons-logo {
    position: absolute;
    width: 257.54px;
    height: 169.64px;
    left: calc(50% - 12.5px);
    top: calc(50% - 169.64px / 2 + 50.32px);
  }

  #social-links {
    display: none;
  }

  #header-arrow-down {
    display: none;
  }

  #icones {
    display: none;
  }
}
@media (min-width: 1367px) {
  
  #tecnologiaLogo {
    position: absolute;
    width: 421.83px;
    height: 277.85px;
    left: calc(50% - 421.83px / 2 + 286.92px);
    top: calc(50% - 277.85px / 2 + 51.92px);
  }
  .fundoPrincipalHome {
    margin: 0;
    max-width: 2560px;

    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  #social-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;
    gap: 10px;
    position: absolute;
    width: 190px;
    height: 30px;
    left: calc(50% - 190px / 2 + 558px);
    bottom: 30px;
  }
  #icones {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border: none;
  }
}
