@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url("../db.onlinewebfonts/fonts.css");
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap');

.bloco1 {
  margin: 0;
  padding: 0;
  width: 1366px;
  margin: auto;
  margin-top: 100px;
  display: flex;
}
.col-a {
  width: 620px;
  height: 795px;
  margin-left: 100px;
}

#text1 {
  max-width: 620px;
  height: 144px;
  margin-top: 50px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;

  color: #8f53a1;
}

#text2 {
  max-width: 520px;
  height: 396px;
  margin-top: 50px;
  margin-left: 100px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;

  color: #1f1f1f;
}

.col-a img {
  width: 620px;
  height: 155px;
}

.col-b {
  background-image: url(../../assets/Pumpkin/homemCelular.jpg);

  width: 546px;
  height: 695px;
  margin-left: 100px;
  margin-top: 100px;
}
.col-b img {
  width: 546px;
  height: 695px;
}

@media (min-width: 320px) and (max-width: 360px) {
  .bloco1 {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 360px;
    height: 1035px;
    margin: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .col-a {
    width: 360px;
    height: 795px;
    margin: 0;

    position: absolute;
  }

  #text1 {
    width: 300px;
    height: 144px;
    margin-top: 25px;
    margin-left: 30px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;

    color: #8f53a1;
  }

  #text2 {
    width: 300px;
    height: 432px;
    margin-left: 30px;
    margin-top: 360px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    color: #1f1f1f;
  }

  .col-a img {
    width: 300px;
    height: 75px;
    margin-left: 30px;
  }

  .col-b {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(../../assets/Pumpkin/homemCelularAndroid.jpg);
    margin: 0;
    margin-top: 270px;
    width: 100%;
    max-width: 360px;
    height: 300px;
    position: absolute;
  }
}

@media (min-width: 361px) and (max-width: 730px) {
  .bloco1 {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 480px;
    height: 1035px;
    margin: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .col-a {
    width: 375px;
    height: 1035px;
    margin: 0;

    position: absolute;
  }

  #text1 {
    width: 300px;
    height: 144px;
    margin-top: 25px;
    margin-left: 30px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;

    color: #8f53a1;
  }

  #text2 {
    width: 315px;
    height: 432px;
    margin-left: 30px;
    margin-top: 355px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    color: #1f1f1f;
  }

  .col-a img {
    width: 315px;
    height: 78.75px;
    margin-left: 30px;
  }

  .col-b {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(../../assets/Pumpkin/homemCelularIphone.png);
    margin: 0;
    margin-top: 270px;
    width: 100%;
    max-width: 480px;
    height: 300px;
    position: absolute;
  }
  .col-b img {
    width: 375px;
    height: 695px;
  }
}

@media (min-width: 731px) and (max-width: 1009px) {
  .bloco1 {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 876px;
    margin: auto;
    margin-top: 50px !important;
    display: flex;
  }
  .col-a {
    width: 400px;
    height: 876px;
    margin-left: 50px;
  }

  #text1 {
    max-width: 400px;
    height: 192px;
    margin: 0;
    margin-top: 50px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;

    color: #8f53a1;
  }

  #text2 {
   max-width: 400px;
    height: 484px;
    margin: 0;
    margin-top: 50px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;

    color: #1f1f1f;
  }

  .col-a img {
    width: 400px;
    height: 100px;
  }

  .col-b {
    background-image: url(../../assets/Pumpkin/homemCelularTablet.jpg);

    width: 334px;
    height: 826px;
    margin-left: 50px;
    margin-top: 50px;
  }
}

/*BLOCO TODO NEGÓCIO É DIGITAL*/

.digital {
  width: 1366px;
  height: 593px;
  margin: auto;
  margin-top: 100px;
  position: relative;
}
.bloco {
  display: flex;
  width: 1366px;
  height: 593px;
}
#titulo {
  position: absolute;
  width: 776px;
  height: 96px;
  left: 295px;
  top: 0px;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 64px;

  color: #1f1f1f;
}
span {
  font-size: 100px;
}

.col1 {
  width: 548px;
  height: 593px;

  background-image: url(../../assets/Pumpkin/mulher.jpg);
}

.col2 {
  width: 818px;
  height: 593px;
  margin-left: 100px;
}

#p1 {
  width: 602px;
  height: 96px;
  margin-top: 171px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;

  color: #8f53a1;
}

#p2 {
  position: absolute;
  width: 518px;
  height: 176px;
  margin-left: 100px;
  margin-top: 50px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  color: #1f1f1f;
}

@media (min-width: 320px) and (max-width: 360px) {
  .digital {
    width: 100%;
    max-width: 360px;
    height: 663px;
    margin: auto;
    margin-top: 50px;
    position: relative;
  }
  .bloco {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    width: 100%;
    height: 663px;
  }
  #titulo {
    position: absolute;
    width: 329px;
    height: 133px;
    left: 15px;
    top: 84px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 76px;
    /* or 190% */

    color: #ffffff;
  }
  span {
    font-size: 100px;
  }

  .col1 {
    width: 100%;
    max-width: 360px;
    height: 487px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(../../assets/Pumpkin/mulherAndroid.jpg);
  }

  .col2 {
    width: 360px;
    height: 593px;
    margin: 0;
    
  }

  #p1 {
    position: absolute;
    width: 315px;
    height: 108px;
    margin: 0;
    margin-left: 30px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* or 150% */

    color: #8f53a1;
  }

  #p2 {
    position: absolute;
    width: 315px;
    height: 180px;
    margin: 0;
    margin-left: 30px;
    margin-top: 186px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    color: #1f1f1f;
    /* or 183% */

    color: #1f1f1f;
  }
}

@media (min-width: 361px) and (max-width: 730px) {
  .digital {
    width: 100%;
    max-width: 480px;
    height: 663px;
    margin: auto;
    margin-top: 50px;
    position: relative;
    
  }
  .bloco {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    height: 663px;
  }
  #titulo {
    position: absolute;
    width: 329px;
    height: 133px;
    left: 30px;
    top: 84px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 76px;
    /* or 190% */

    color: #ffffff;
  }
  span {
    font-size: 100px;
  }

  .col1 {
    width: 100%;
    max-width: 480px;
    height: 487px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(../../assets/Pumpkin/mulherIphone.jpg);
  }

  .col2 {
    width: 375px;
    height: 593px;
    margin: 0;
    
  }

  #p1 {
    position: absolute;
    width: 315px;
    height: 108px;
    margin: 0;
    margin-left: 30px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* or 150% */

    color: #8f53a1;
  }

  #p2 {
    position: absolute;
    width: 315px;
    height: 180px;
    margin: 0;
    margin-left: 30px;
    margin-top: 170px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    color: #1f1f1f;
    /* or 183% */

    color: #1f1f1f;
  }
}

@media (min-width: 731px) and (max-width: 1009px) {
  .digital {
    width: 100vw;
    height: 663px;
    margin: auto;
    margin-top: 50px;
    position: relative;
  }
  .bloco {
    display: flex;
    width: 100vw;
    height: 663px;
  }
  #titulo {
    width: 634px;
    height: 128px;
    left: 106px;
    top: 0px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 76px;

    color: #1f1f1f;
  }
  span {
    font-size: 100px;
  }

  .col1 {
    width: 506px;
    height: 663px;

    background-image: url(../../assets/Pumpkin/mulherTablet.jpg);
  }

  .col2 {
    width: 818px;
    height: 593px;
    margin-left: 50px;
  }

  #p1 {
    position: absolute;
    width: 384px;
    height: 192px;

    top: 151px;
    margin: 0;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    /* or 150% */

    color: #8f53a1;
  }

  #p2 {
    position: absolute;
    width: 384px;
    height: 220px;
    margin: 0;
    top: 393px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    /* or 183% */

    color: #1f1f1f;
  }
}
