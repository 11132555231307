.translate{
  display: flex;
  
  
}
.translate span {
  width: 21px;
  height: 19px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  

  display: flex;
  align-items: center;
  letter-spacing: 0.07em;

  color: #ffffff;

  
  margin-left: 15px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
