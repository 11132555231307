
.unsubFundo{
  width: 100vw;
  height: 100vh;
  background-color:#8F53A1;
}

.unsubFundo h3{
max-width: 520px;
width: 100%;
height: 48px;
margin-left: 100px;
top: 200px;
position:absolute;
font-family: 'Work Sans';
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 48px;
/* identical to box height, or 150% */


color: #FFFFFF;
}

.unsubFundo p{
  max-width: 520px;
  width: 100%;
  height: 88px;
  left: 100px;
  top: 298px;
  position:absolute;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 44px;
  /* or 183% */
  
  
  color: #FFFFFF;
}


@media (max-width: 540px){
  .unsubFundo h3{
    max-width: 230px;
    width: 100%;
    height: 48px;
    left: 30px;
    top: 200px;
    margin:0;
    position:absolute;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height, or 150% */
    
    
    color: #FFFFFF;
    }
    
    .unsubFundo p{
      max-width: 290px;
      width: 100%;
      height: 88px;
      left: 30px;
      top: 320px;
      position:absolute;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 44px;
      /* or 183% */
      
      
      color: #FFFFFF;
    }
}

