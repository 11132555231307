.container__toogle .container__toogle + button {
  background: red;
  padding: 20px;
}

#container-nav {
  width: 100vw;
  height: 100px;
}

#menuReduzido {
  display: none;
}

.px-5-nav {
  padding-left: 3rem;
  padding-right: 3rem;
}

.navbar-toggler {
  height: 50px;
}

#nav-ul {
  width: 589px;
  height: 23px;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

#nav-ul li a {
  color: white;
}

#nav-ul > li {
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  width: 120px;
  height: 23px;
  position: relative;
  max-width: min-content;
  font-family: "Work Sans";
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0px;
  color: white;
}

#nav-ul-menu-reduzido {
  width: 100vw;
  height: 177vw;
  background: rgba(2, 1, 10, 1);
  z-index: 999;
  position: relative;
}

.nav-bar1 {
  transition-duration: 1s;
  transition-property: height, background-color;
}

.navbar {
  height: 100px;
  
}
.navbar-nav{
  cursor: pointer;
}

#traducao a {
  width: 24px;
  height: 19px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.07em;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

#active {
  color: white;
}

@media (min-width: 320px) and (max-width: 471px) {
  #menuReduzido {
    display: block !important;
    width: 40px;
    height: 40px;
  }

  #reduzido {
    width: 100vw;
  }
  .open,
  .open > .navbar {
    background-color: #02010a;
  }

  .open #logo-menu-nav-top {
    margin-left: -18px !important;
  }

  #nav-ul-menu-reduzido {
    margin: 0;
    width: 100vw;
    height: 90vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .tituloMenuReduzido {
    width: 120px;
    height: 23px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;

    color: #ffffff;


    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .todosTitulos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;

    position: absolute;
    width: 315px;
    height: 243px;
    left: calc(50% - 315px / 2);
    top: 20px;
  }

  .setaMenuReduzido {
    position: absolute;
    left: 287px;
  }

  .linha {
    position: absolute;
    width: 315px;
    height: 1px;
    margin-top: 23.5px;
    background: #ffffff;
  }

  #traducao {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 20px;

    position: absolute;
    width: 89px;
    height: 28px;
    left: calc(50% - 89px / 2 - 8px);
    bottom: 93px;
  }

  #social-links-menu-reduzido {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;
    gap: 20px;

    position: absolute;
    width: 230px;
    height: 30px;
    left: calc(50% - 230px / 2 - 7.5px);
    bottom: 43px;
  }
  .px-5-nav {
    padding-left: 3rem;
    padding-right: 1rem;
  }
}

@media (min-width: 475px) and (max-width: 991px) {
  #menuReduzido {
    width: 40px;
    height: 40px;
    display: block !important;
  }
  .px-5-nav {
    padding-left: 3rem;
    padding-right: 2rem;
  }
  .open,
  .open > .navbar {
    background-color: #02010a;
  }
  .open #logo-menu-nav-top {
    margin-left: -18px !important;
  }

  #reduzido {
    width: 100vw;
  }
  .yuo{
    margin: 0;
    height: 85vh !important;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #nav-ul-menu-reduzido {
    width: 100vw;
    
    background: rgba(2, 1, 10, 1);
    position: relative;

    
  }
  .tituloMenuReduzido {
    width: 192px;
    height: 38px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .todosTitulos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;

    position: absolute;
    width: 774px;
    height: 335px;
    left: calc(50% - 774px / 2);
    top: 50px;
  }

  .setaMenuReduzido {
    position: absolute;
    left: 745px;
  }

  .linha {
    position: absolute;
    width: 774px;
    height: 1px;
    margin-top: 23.5px;
    background: #ffffff;
  }

  #traducao {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 30px;

    position: absolute;
    width: 122px;
    height: 38px;
    left: calc(50% - 122px / 2 - -14.5px);
    bottom: 113px;
  }

  #social-links-menu-reduzido {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;
    gap: 30px;

    position: absolute;
    width: 320px;
    height: 40px;
    left: calc(50% - 320px / 2);
    bottom: 43px;
  }
}

@media (min-width: 2559px) {
  .px-5-nav {
    padding-left: 600px;
    padding-right: 600px;
  }

  #menuReduzido {
    display: none;
  }
}
