/*
      www.OnlineWebFonts.Com 
      You must credit the author Copy this link on your web 
      <div>Font made from <a href="http://www.onlinewebfonts.com">oNline Web Fonts</a>is licensed by CC BY 3.0</div>
      OR
      <a href="http://www.onlinewebfonts.com">oNline Web Fonts</a>
*/
@font-face {font-family: "SF Pro Text";
    src: url("./236987803794b916d95e7fd41ed4fee0.eot"); /* IE9*/
    src: url("./236987803794b916d95e7fd41ed4fee0-ie-fix.eot") format("embedded-opentype"), /* IE6-IE8 */
    url("./236987803794b916d95e7fd41ed4fee0.woff2") format("woff2"), /* chrome firefox */
    url("./236987803794b916d95e7fd41ed4fee0.woff") format("woff"), /* chrome firefox */
    url("./236987803794b916d95e7fd41ed4fee0.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
}