@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("../db.onlinewebfonts/fonts.css");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap");

.fundoContato {
  width: 1366px;
  height: 733px;
  position: relative;
  background: #c2dfe3;

  margin: auto;
}

.fale img {
  width: 203.96px;
  height: 193.3px;
  position: absolute;
  left: 25px;
  top: -10.86px;
}
.fale h3 {
  position: absolute;
  width: 381px;
  height: 51px;
  left: calc(50% - 340px / 2 - 248px);
  top: 100px;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;

  /* identical to box height */

  color: #8f53a1;
}

.contact {
  width: 966px;
  height: 400px;
  top: 226px;
  left: 200px;
  display: flex;
  justify-content: space-between;
  position: absolute;
}

.entrada {
  background-color: #c2dfe3;
  width: 470.5px;
  height: 60px;
  border: 2px solid #8f53a1;
  margin-top: 25px;
  color: #8f53a1;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.inputEntrada {
  width: 440px;
  height: 100%;
  background-color: #c2dfe3;
  outline: none;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  margin-left: 15px;
  color: #8f53a1;
}

input::placeholder {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #8f53a1;
}

#erro {
  font-family: "Work Sans";
  color: red;
  font-size: 18px;
  margin-left: 15px;
}
textarea::placeholder {
  color: #8f53a1;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

textarea::-webkit-scrollbar {
  width: 7px;
  height: 50px;
  /* width of the entire scrollbar */
}

textarea::-webkit-scrollbar-track {
  background: white;
  
}

textarea::-webkit-scrollbar-thumb {
  background-color: #8f53a1;

  
}

#mensagem {
  border: 2px solid #8f53a1;
  background-color: #c2dfe3;
  width: 470.5px;
  height: 230px;
  margin-top: 25px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  color: #8f53a1;
}

#textarea {
  background-color: #c2dfe3;
  width: 440.5px;
  height: 185px;
  margin-top: 10px;
  margin-left: 10px;
  outline: none;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  color: #8f53a1;
  padding: 10px;
  padding-bottom: 0;
  resize: none;
}

#fundoContact{
  background-color: #c2dfe3;
}
.fundoContact{
  background-color: #c2dfe3;
  width: 100vw;
}


#botao {
  width: 200px;
  height: 60px;
  z-index: 999;
  background: #8f53a1;
  color: white;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  flex: none;
  order: 2;
  flex-grow: 0;
  position: absolute;
  top: 586px;
  left: 966px;
  transition: transform 100ms ease-out;
}

#fundoBotao {
  width: 200px;
  height: 60px;
  background-color: white;
  position: absolute;
  top: 596px;
  left: 976px;
}

#botao:active {
  /* transform: translateY(10px); */
  transform: translate(10px, 10px);
}

#seta {
  position: absolute;
  width: 353px;
  height: 259px;
  left: 988px;
  top: 525px;
}

.lottiePumpkin {
  background: #c2dfe3;
  position: relative;
  width: 1366px;
  height: 726px;
  margin: auto;

}

.lottiePumpkin svg {
  position: absolute;
  width: 695px !important;
  height: 227px !important;
  left: 336px;
  top: 250px;
}
.lottiePumpkin2{
  display: none;
}

#iconEnviar {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 85px;
  top: 15px;

  background: #ffffff;
  border-radius: 15px;
}

@media (min-width: 320px) and (max-width: 360px) {
  .fundoContato {
    width: 100%;
    max-width: 360px;
    height: 860px;
    position: relative;
    background: #c2dfe3;
    
    margin: auto;
  }
  #fundoContact{
    background-color: #c2dfe3;
  }
  .fundoContact{
    background-color: #c2dfe3;
    width: 100vw;
  }

  .fale img {
    width: 53.75px;
    height: 51.21px;
    position: absolute;
    left: 6.59px;
    top: -6px;
  }
  .fale h3 {
    position: absolute;
    width: 272px;
    height: 41px;
    left: calc(50% - 238px / 2);
    top: 29px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #8f53a1;
    
  }

  .contact {
    width: 300px;
    height: 610px;
    top: 116px;
    left: 0px;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
  }

  .entrada {
    background-color: #c2dfe3;
    width: 300px;
    height: 60px;
    border: 2px solid #8f53a1;
    margin-top: 25px;
    color: #8f53a1;
    padding: 5px;
  }

  .entrada:first-child {
    margin: 0;
  }

  .inputEntrada {
    width: 270px;
    height: 50px;
    background-color: #c2dfe3;
    outline: none;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-left: 15px;
    color: #8f53a1;
  }

  input::placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  #erro {
    font-family: "Work Sans";
    color: red;
    font-size: 18px;
  }
  textarea::placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  textarea::-webkit-scrollbar {
    width: 7px;
    height: 50px;
    /* width of the entire scrollbar */
  }

  textarea::-webkit-scrollbar-track {
    background: white;
    /* color of the tracking area */
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #8f53a1;
  }

  #mensagem {
    border: 2px solid #8f53a1;
    background-color: #c2dfe3;
    width: 300px;
    height: 180px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
  }

  #textarea {
    background-color: #c2dfe3;
    width: 290px;
    height: 138px;
    margin: 0;
    margin-top: 10px;

    outline: none;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
    padding: 10px;
    resize: none;
  }

  #botao {
    width: 300px;
    height: 60px;
    z-index: 999;
    background: #8f53a1;
    color: white;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    flex: none;
    order: 2;
    flex-grow: 0;
    position: absolute;
    top: 756px;
    left: 30px;
    transform: translateY(-10px);
  }

  #fundoBotao {
    width: 300px;
    height: 60px;
    background-color: white;
    position: absolute;
    top: 756px;
    left: 40px;
  }

  #botao:active {
    transition: width 1000s, height 100000s;
    transform: translateX(10px);
  }

  #seta {
    display: none;
  }
  .lottiePumpkin {
    display: none;
  }
  .lottiePumpkin2 {
    background: #c2dfe3 !important;
    position: relative !important;
    width: 360px !important;
    height: 950px;
    margin: auto ;
    margin-top: 120px !important;
    z-index: 999;
    display: block;
  }
  .lottiePumpkin2 svg {
    width: 98% !important;
    height: 35% !important;
    transform: translate3d(0px, 0px, 0px);
    content-visibility: visible !important;
    top: 102.47px  !important;
    position: absolute;
    display: block;
  }
  #agradecimentoContato{
   
    width: 300px;
    height: 200px;
    margin-inline: 30px;
    position: absolute;
    top: 450px;
    
}

  #agradecimentoContato h1{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 51px;
    
    align-items: center;
    text-align: center;
    
    color: #8F53A1;
  }
  #agradecimentoContato h2{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 51px;
   
    align-items: center;
    text-align: center;
    
    color: #8F53A1;
  }
 
  
  .lottiePumpkin svg {
    display: none;
  }

  #iconEnviar{
    position: absolute;
    width: 40px;
    height: 40px;
    left: 130px;
    top: 10px;
    background: #ffffff;
    border-radius: 25px;
  }

  
}

@media (min-width: 361px) and (max-width: 629px) {
  .fundoContato {
    width: 100vw;
    height: 950px;
    position: relative;
    background: #c2dfe3;
    top: 30px;
    margin: auto;
  }
  #fundoContact{
    background-color: #c2dfe3;
  }
  .fundoContact{
    background-color: #c2dfe3;
    width: 100vw;
  }

  .fale img {
    width: 95.56px;
    height: 91.03px;
    position: absolute;
    left: 11.71px;
    top: -7.23px;
  }
  .fale h3 {
    position: absolute;
    width: 223px;
    height: 41px;
    left: calc(50% - 124px / 2);
    top: 29px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #8f53a1;
   
}

 
  

  .contact {
    max-width: 315px;
    width: 100%;
    height: 610px;
    top: 116px;
    left: 0px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    margin:auto;
  }

  .entrada {
    background-color: #c2dfe3;
    max-width: 315px;
    width: 100%;
    height: 60px;
    border: 2px solid #8f53a1;
    margin-top: 25px;
    color: #8f53a1;
    padding: 5px;
  }

  .entrada:first-child {
    margin: 0;
  }

  .inputEntrada {
    max-width: 290px;
    width: 100%;
    height: 50px;
    background-color: #c2dfe3;
    outline: none;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-left: 15px;
    color: #8f53a1;
  }

  input::placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  #erro {
    font-family: "Work Sans";
    color: red;
    font-size: 18px;
  }
  textarea::placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  textarea::-webkit-scrollbar {
    width: 7px;
    height: 50px;
    /* width of the entire scrollbar */
  }

  textarea::-webkit-scrollbar-track {
    background: white;
    /* color of the tracking area */
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #8f53a1;

    /* color of the scroll thumb */
    /* creates padding around scroll thumb */
  }

  #mensagem {
    border: 2px solid #8f53a1;
    background-color: #c2dfe3;
    width: 315px;
    height: 180px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
  }

  #textarea {
    background-color: #c2dfe3;
    width: 290px;
    height: 138px;
    margin: 0;
    margin-top: 10px;

    outline: none;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
    padding: 10px;
    resize: none;
  }

  #botao {
    width: 315px;
    height: 60px;
    z-index: 999;
    background: #8f53a1;
    color: white;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    flex: none;
    order: 2;
    flex-grow: 0;
    position: absolute;
    top: 756px;
    left: 0px;
    transform: translateY(-10px);
    right: 0px;
    margin: auto;
  }

  #fundoBotao {
    width: 315px;
    height: 60px;
    background-color: white;
    position: absolute;
    top: 756px;
    right: 0px;
    left: 20px;
    margin: auto;
  }

  #botao:active {
    transition: width 1000s, height 100000s;
    transform: translateX(10px);
  }

  #seta {
    display: none;
  }

  .lottiePumpkin {
    display: none;
  }
  .lottiePumpkin2 {
    background: #c2dfe3;
    position: relative;
    width: 375px;
    height: 950px;
    margin: auto;
    display: block;
  }
  .lottiePumpkin2 svg {
    width: 98% !important;
    height: 35% !important;
    transform: translate3d(0px, 0px, 0px);
    content-visibility: visible;
    top: 102.47px;
    position: absolute;
    display: block;
  }
  #agradecimentoContato{
   
    width: 300px;
    height: 200px;
    margin-inline: 40px;
    position: absolute;
    top: 450px;
    
}

  #agradecimentoContato h1{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 51px;
    
    align-items: center;
    text-align: center;
    
    color: #8F53A1;
  }
  #agradecimentoContato h2{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 51px;
   
    align-items: center;
    text-align: center;
    
    color: #8F53A1;
  }
 
  
  .lottiePumpkin svg {
    display: none;
  }

  #iconEnviar{
    position: absolute;
    width: 40px;
    height: 40px;
    left: 140px;
    top: 10px;
    background: #ffffff;
    border-radius: 25px;
  }
}
@media (min-width: 629px) and (max-width: 789px) {
  .fundoContato {
    width: 100vw;
    height: 686px;
    position: relative;
    background: #c2dfe3;
    top: 100px;
    margin: auto;
  }
  #fundoContact{
    background-color: #c2dfe3;
  }
  .fundoContact{
    background-color: #c2dfe3;
    width: 100vw;
  }

  .fale img {
    width: 124.53px;
    height: 118.63px;
    position: absolute;
    left: 9.84px;

    top: -10.86px;
  }
  .fale h3 {
    position: absolute;
    width: 393px;
    height: 51px;
    left: 168px;
    top: 50px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #8f53a1;
   
  }

  .contact {
    max-width: 600px;
    width: 100%;
    height: 610px;
    top: 151px;
    left: 0px;
    right: 0;
    display: flex;

    position: absolute;
    margin: auto;
  }

  .entrada {
    background-color: #c2dfe3;
    width: 282.5px;

    height: 60px;
    border: 2px solid #8f53a1;
    margin-top: 25px;
    color: #8f53a1;
    padding: 5px;
  }

  .entrada:first-child {
    margin: 0;
  }

  .inputEntrada {
    max-width: 245px;
    width: 100%;
    height: 50px;
    background-color: #c2dfe3;
    outline: none;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-left: 15px;
    color: #8f53a1;
  }

  input::placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  #erro {
    font-family: "Work Sans";
    color: red;
    font-size: 18px;
  }
  textarea::placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  textarea::-webkit-scrollbar {
    width: 7px;
    height: 50px;
    /* width of the entire scrollbar */
  }

  textarea::-webkit-scrollbar-track {
    background: white;
    /* color of the tracking area */
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #8f53a1;

    /* color of the scroll thumb */
    /* creates padding around scroll thumb */
  }

  #mensagem {
    border: 2px solid #8f53a1;
    background-color: #c2dfe3;
    width: 284.5px;
    height: 230px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
  }

  #textarea {
    background-color: #c2dfe3;
    width: 266px;
    height: 187px;
    margin: 0;
    margin-top: 10px;

    outline: none;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
    padding: 10px;
    resize: none;
  }

  #botao {
    width: 200px;
    height: 60px;
    z-index: 999;
    background: #8f53a1;
    color: white;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    flex: none;
    order: 2;
    flex-grow: 0;
    position: absolute;
    top: 491px;
    left: 364px;
    transform: translateY(-10px);
  }

  #fundoBotao {
    width: 200px;
    height: 60px;
    background-color: white;
    position: absolute;
    top: 491px;
    left: 371px;
  }

  #botao:active {
    transition: width 1000s, height 100000s;
    transform: translateX(10px);
    background-image: url(../../assets/Contact/Check.svg);
  }

  #seta {
    display: none;
  }
  .lottiePumpkin {
    background: #c2dfe3;
    position: relative;
    width: 834px;
    margin: auto;
    height: 686px;
  }
  
  .lottiePumpkin svg {
    position: absolute;
    width: 645px !important;
    height: 227px !important;
    left: 130px;
    top: 230px;
  }

  .lottiePumpkin2{
    display: none;
  }
  .lottiePumpkin2 svg{
  display: none;
}
#agradecimentoContato{
  display: none;
}
}
@media (min-width: 790px) and (max-width: 1009px) {
  .fundoContato {
    width: 100vw;
    height: 686px;
    position: relative;
    background: #c2dfe3;
    top: 100px;
    margin: auto;
  }
  #fundoContact{
    background-color: #c2dfe3;
  }
  .fundoContact{
    background-color: #c2dfe3;
    width: 100vw;
  }

  .fale img {
    width: 124.53px;
    height: 118.63px;
    position: absolute;
    left: 9.84px;

    top: -10.86px;
  }
  .fale h3 {
    position: absolute;
    width: 393px;
    height: 51px;
    left: 168px;
    top: 50px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #8f53a1;
   
  }

  .contact {
    max-width: 734px;
    width: 100%;
    height: 610px;
    top: 151px;
    left: 0;
    display: flex;
    position: absolute;
    margin: auto;
    right: 0;
  }

  .entrada {
    background-color: #c2dfe3;
    width: 354.5px;

    height: 60px;
    border: 2px solid #8f53a1;
    margin-top: 25px;
    color: #8f53a1;
    padding: 5px;
  }

  .entrada:first-child {
    margin: 0;
  }

  .inputEntrada {
    max-width: 325px;
    width: 100%;
    height: 50px;
    background-color: #c2dfe3;
    outline: none;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-left: 15px;
    color: #8f53a1;
  }

  input::placeholder {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #8f53a1;
  }

  #erro {
    font-family: "Work Sans";
    color: red;
    font-size: 18px;
  }
  textarea::placeholder {
    color: #8f53a1;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  textarea::-webkit-scrollbar {
    width: 7px;
    height: 50px;
    /* width of the entire scrollbar */
  }

  textarea::-webkit-scrollbar-track {
    background: white;
    /* color of the tracking area */
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #8f53a1;

    /* color of the scroll thumb */
    /* creates padding around scroll thumb */
  }

  #mensagem {
    border: 2px solid #8f53a1;
    background-color: #c2dfe3;
    width: 354.5px;
    height: 230px;
    margin-top: 25px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
  }

  #textarea {
    background-color: #c2dfe3;
    width: 290px;
    height: 187px;
    margin: 0;
    margin-top: 10px;

    outline: none;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #8f53a1;
    padding: 10px;
    resize: none;
  }

  #botao {
    width: 200px;
    height: 60px;
    z-index: 999;
    background: #8f53a1;
    color: white;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    flex: none;
    order: 2;
    flex-grow: 0;
    position: absolute;
    top: 491px;
    left: 564px;
    transform: translateY(-10px);
  }

  #fundoBotao {
    width: 200px;
    height: 60px;
    background-color: white;
    position: absolute;
    top: 491px;
    left: 575px;
  }

  #botao:active {
    transition: width 1000s, height 100000s;
    transform: translateX(10px);
    background-image: url(../../assets/Contact/Check.svg);
  }

  #seta {
    display: none;
  }
  .lottiePumpkin {
    background: #c2dfe3;
    position: relative;
    width: 834px;
    margin: auto;
    height: 686px;
  }
  
  .lottiePumpkin svg {
    position: absolute;
    width: 645px !important;
    height: 227px !important;
    left: 130px;
    top: 230px;
  }

  .lottiePumpkin2{
    display: none;
  }
  .lottiePumpkin2 svg{
  display: none;
}
#agradecimentoContato{
  display: none;
}
}