@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("../db.onlinewebfonts/fonts.css");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&family=Work+Sans:wght@500&display=swap");

.html {
  scroll-behavior: smooth;
}

.container-footer {
  
  width: 100vw;
  height: 371px;
  background-color: #8f53a1;
  position: relative;
}

.footer {
  width: 1366px;
  height: 371px;
  margin: auto;
  display: flex;
}

.footerA {
  width: 683px;
  display: flex;
  flex-direction: column;
}
.menu{
  cursor: pointer;
}


#logoFooter {
  width: 271.99px;
  height: 70px;
  margin-left: 100px;
  margin-top: 50px;
}

.menu {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-left: 100px;
  margin-top: 25px;
  text-decoration: none;
}

#menu1 {
  margin-top: 50px;
}

.menu:hover {
  color: white;
}

.footerB {
  width: 683px;
  display: flex;
  flex-direction: column;
}

#icones-footer {
  margin-left: 10px;
}
#icons-footer {
  margin-top: 71px;
  margin-left: 400px;
  display: flex;
}

#p1-footer {
  width: 304px;
  height: 14px;
  margin: 0;
  position: absolute;
  margin-top: 275px;
  margin-left: 369px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

#p2-footer {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 200px;
  margin-left: 295px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

#p2-footer span {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #e3a14e;
}

@media (min-width: 320px) and (max-width: 360px) {
  .container-footer {
    width: 100vw;
    height: 487px;
    background-color: #8f53a1;
    position: relative;
  }

  .footer {
    width: 360px;
    height: 487px;
    position: absolute;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .footerA {
    width: 360px;
    height: 243px;
    display: flex;
    flex-direction: column;
    z-index: 999;
  }

  #logoFooter {
    width: 271.99px;
    height: 70px;
    margin-left: 30px;
    margin-top: 25px;
  }

  #menu1 {
    margin-top: 105px;
  }

  .menu {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    align-items: center;
    margin-left: 30px;
    z-index: 999;
    color: #ffff;
  }

  .footerB {
    width: 360px;
    height: 243px;
    display: flex;
    flex-direction: column;
    
    
  }

  #icons-footer {
    position: absolute;
    width: 250px;
    height: 30px;
    left: -378px;
    top: 50px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 14px;
  }

  #p1-footer {
    position: absolute;
    width: 242px;
    height: 14px;
    margin: 0;
    left: 30px;
    top: 401px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;

    color: #ffff;
  }

  #p2-footer {
    position: absolute;
    width: 218px;
    height: 28px;
    margin: 0;
    left: 30px;
    top: 421px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    text-align: left;

    color: #ffffff;
  }

  #p2-footer span {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #e3a14e;
  }
}

@media (min-width: 361px) and (max-width: 480px) {
  .container-footer {
    width: 100vw;
    height: 487px;
    background-color: #8f53a1;
    position: relative;
  }

  .footer {
    width: 375px;
    height: 487px;
    position: absolute;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .footerA {
    width: 375px;
    height: 243px;
    display: flex;
    flex-direction: column;
    z-index: 999;
  }

  .footerA Link{
    cursor: pointer;
  }

  #logoFooter {
    width: 271.99px;
    height: 70px;
    margin-left: 30px;
    margin-top: 25px;
  }


  #menu1 {
    margin-top: 105px;
  }

  .menu {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    z-index: 999;
    display: flex;
    align-items: center;
    margin-left: 30px;

    color: #ffff;
  }

  .footerB {
    width: 375px;
    height: 243px;
    display: flex;
    flex-direction: column;
    
  }

  #icons-footer {
    position: absolute;
    width: 250px;
    height: 30px;
    left: -378px;
    top: 50px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 14px;
  }

  #p1-footer {
    position: absolute;
    width: 242px;
    height: 14px;
    margin: 0;
    left: 30px;
    top: 401px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;

    color: #ffff;
  }

  #p2-footer {
    position: absolute;
    width: 218px;
    height: 28px;
    margin: 0;
    left: 30px;
    top: 421px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    text-align: left;

    color: #ffffff;
  }

  #p2-footer span {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #e3a14e;
  }
}

@media (min-width: 481px) and (max-width: 1009px) {
  .container-footer {
    width: 100vw;
    height: 371px;
    background-color: #8f53a1;
    position: relative;
  }

  .footer {
    max-width: 834px;
    min-width: 660px;
    height: 371px;
    display: flex;
    flex-direction: row;
  }

  .footerA {
    width: 417px;
    height: 371px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  #logoFooter {
    width: 271.99px;
    height: 70px;
    margin-left: 100px;
    margin-top: 50px;
  }

  .menu {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-left: 100px;
    margin-top: 25px;
  }

  #menu1 {
    margin-top: 50px;
  }

  .footerB {
    width: 417px;
    height: 371px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 999;
  }

  #icones {
    position: absolute;
    z-index: 999;
    margin-top: 10px;
  }
  #icons-footer {
    margin: 0;
    position: absolute;
    width: 190px;
    height: 30px;
    right: 100px;
    top: 71px;
  }

  #p1-footer {
    position: absolute;
    width: 238px;
    height: 14px;
    right: 100px;
    bottom: 70px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    display: flex;
    align-items: center;
    text-align: right;
    color: #ffffff;
  }

  #p2-footer {
    position: absolute;
    width: 312px;
    height: 14px;
    right: 100px;
    bottom: 50px;

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  #p2-footer span {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #e3a14e;
  }
}
