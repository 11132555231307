.tecnologias {
  width: 1366px;
  height: 600px;
  position: relative;
  margin: auto;
}

#Tec-p {
  position: absolute;
  top: 200px;
  left: 154px;
  width: 1057px;
  height: 60px;
  margin: auto;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 60px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #1f1f1f;
}

.section-tec{
  position: absolute;
  top: 310px;
}

.container-tec-A {
  display: flex;
  margin-left: 100px;
  
}
.container-tec-B {
  display: flex;
  margin-left: 100px;
  margin-top: 50px;
  
}
.tecnologias-svg {
  width: 80px;
  height: 80px;
  margin-left: 50px;
}

#tec-svg-1 {
  margin-left: 40px;
}
#tec-svg-2 {
  margin-left: 60px;
}

.tecB {
  width: 1166px;
  height: 80px;
  margin: auto;
  margin-top: 50px;
}

#bloco-tec {
  position: absolute;
  width: 535px;
  height: 100px;
  right: 0px;
  top: 0px;
  background: #c2dfe3;
}

@media (min-width: 320px) and (max-width: 360px) {
  .tecnologias {
    width: 360px;
    height: 420px;
    position: relative;
    margin: auto;
  }

  #Tec-p {
    position: absolute;
    width: 300px;
    height: 138px;
    left: calc(50% - 300px / 2 + 0.5px);
    top: 50px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

  .container-tec-A {
    display: flex;
    margin-left: 30px;
    flex-direction: column-reverse;
    
  }
  .container-tec-B {
    display: flex;
    margin-top: 10px;
    margin-left: 30px;
    flex-direction: column;

  }

  .tecnologias-svg {
    width: 40px;
    height: 40px;
    margin-left: 30px;
    display: flex;
    
  }

  .tecA1{
    width: 300px;
    display: flex;
  }
  .tecA2{
    width: 300px;
    display: flex;
       
  }

  .tecB1{
    width: 315px;
    display: flex;
  }
  .tecB2{
    width: 315px;
    margin-top: 10px;
    display: flex;
  }

  .section-tec{
    width: 360px;
    position: absolute;
    top: 213px;
  }

  #tec-svg-1 {
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
  #tec-svg-2 {
    width: 40px;
    height: 40px;
    margin-left: 30px;
  }

  .tecB {
    width: 315px;
    height: 100px;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
  }
  #seta-tec {
    display: none;
  }

  #bloco-tec {
    position: absolute;
    width: 180px;
    height: 25px;
    right: 0px;
    top: 0px;
    background: #c2dfe3;
  }
}

@media (min-width: 361px) and (max-width: 769px) {
  .tecnologias {
    width: 100vw;
    height: 415px;
    position: relative;
    margin: auto;
  }

  #Tec-p {
    position: absolute;
    width: 300px;
    height: 138px;
    left: calc(50% - 300px / 2 + 0.5px);
    top: 50px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

  .container-tec-A {
    display: flex;
    margin-left: 30px;
    flex-direction: column-reverse;
    
  }
  .container-tec-B {
    display: flex;
    margin-top: 10px;
    margin-left: 30px;
    flex-direction: column;

  }

  .tecnologias-svg {
    width: 40px;
    height: 40px;
    margin-left: 30px;
    display: flex;
    
  }

  .tecA1{
    width: 315px;
    display: flex;
  }
  .tecA2{
    width: 315px;
    display: flex;
       
  }

  .tecB1{
    width: 315px;
    display: flex;
  }
  .tecB2{
    width: 315px;
    margin-top: 10px;
    display: flex;
  }

  .section-tec{
    width: 375px;
    position: absolute;
    top: 213px;
    left: calc(50% - 300px/2 + -35.5px);
  }

  #tec-svg-1 {
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
  #tec-svg-2 {
    width: 40px;
    height: 40px;
    margin-left: 30px;
  }

  .tecB {
    width: 315px;
    height: 100px;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
  }
  #seta-tec {
    display: none;
  }

  #bloco-tec {
    position: absolute;
    width: 180px;
    height: 25px;
    right: 0px;
    top: 0px;
    background: #c2dfe3;
  }
}

@media (min-width: 770px) and (max-width: 1009px) {
  .tecnologias {
    width: 100vw;
    height: 460px;
    position: relative;
    margin: auto;
  }

  #Tec-p {
    position: absolute;
    width: 700px;
    height: 120px;
    left: 100px;
    top: 100px;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 60px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

  .section-tec{
    position: absolute;
    top: 270px;
  }

  .container-tec {
    display: flex;
    flex-direction: column;
  }

  .tecA {
    width: 734px;
    height: 50px;
    margin: auto;
    margin-top: 100px;
  }

  .tecnologias-svg {
    width: 50px;
    height: 50px;
    margin-left: 30px;
  }

  #tec-svg-1 {
    width: 50px;
    height: 50px;
    margin-left: 30px;
  }
  #tec-svg-2 {
    width: 50px;
    height: 50px;
    margin-left: 30px;
  }

  .tecB {
    width: 734px;
    height: 50px;
    margin: auto;
    margin-top: 50px;
  }
  #seta-tec {
    width: 215.52px;
    height: 158.13px;
    right: 10px;
    top: 0px;
  }

  #bloco-tec {
    position: absolute;
    width: 300px;
    height: 50px;
    right: 0;
    top: 0px;
    background: #c2dfe3;
  }
}

@media (min-width: 1367px) {
  #seta-tec {
    display: none;
  }
  #Tec-p {
    position: absolute;
    top: 200px;
    left: 154px;
    width: 1057px;
    height: 60px;
    margin: auto;

    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 60px;

    text-align: center;
    letter-spacing: 0.02em;

    color: #1f1f1f;
  }

 .section-tec{
  position: absolute;
  top: 310px;
 }


  .tecA {
    margin-top: 310px;
  }
  #bloco-tec {
    position: absolute;
    width: 535px;
    height: 100px;
    left: 900px;
    right: 0px;
    top: 0px;
    background: #c2dfe3;
  }
}
